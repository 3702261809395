import { IPostFacebookConfigRequest } from 'modules/config/integrationsConfig/domain/dto/FacebookConfig/IPostFacebookConfigRequest';
import { IGetStatusIfoodConfigurationResponse } from 'modules/config/integrationsConfig/domain/dto/GetStatusIfoodConfigurationResponse';
import { IGetMobiConfigResponse } from 'modules/config/integrationsConfig/domain/dto/IGetMobiConfigResponse';
import { IGetOrganizerByDocumentResponse } from 'modules/config/integrationsConfig/domain/dto/IGetOrganizerByDocumentResponse';
import { IGetSankhyaConfigurationResponse } from 'modules/config/integrationsConfig/domain/dto/IGetSankhyaConfigurationResponse';
import { IGetVerifyOrganizerResponse } from 'modules/config/integrationsConfig/domain/dto/IGetVerifyOrganizerResponse';
import { IIntegrationService } from 'modules/config/integrationsConfig/domain/dto/IIntegrationService';
import { IPostMobiConfigRequest } from 'modules/config/integrationsConfig/domain/dto/MobiConfig/IPostMobiConfigRequest';
import { ISankhyaConfigurationRequest } from 'modules/config/integrationsConfig/domain/dto/SankhyaConfiguration/ISankhyaConfigurationRequest';
import { ISankhyaConfigurationResponse } from 'modules/config/integrationsConfig/domain/dto/SankhyaConfiguration/ISankhyaConfigurationResponse';
import { ISankhyaPaymentRequest } from 'modules/config/integrationsConfig/domain/dto/SankhyaPayment/ISankhyaPaymentRequest';
import { ISankhyaPaymentResponse } from 'modules/config/integrationsConfig/domain/dto/SankhyaPayment/ISankhyaPaymentResponse';
import { IIFoodStatusConfigRequest } from 'modules/config/integrationsConfig/domain/dto/iFoodStatusConfig/iFoodStatusConfigRequest';
import { IIFoodStatusConfigResponse } from 'modules/config/integrationsConfig/domain/dto/iFoodStatusConfig/iFoodStatusConfigResponse';
import { IGetSankhyaPaymentFormResponse } from 'modules/config/integrationsConfig/domain/dto/IGetSankhyaPaymentFormResponse';
import { api } from 'services/api/Api';
import { IGetLocalMainDataResponse } from 'modules/config/integrationsConfig/domain/dto/IGetLocalMainDataResponse';
import { IPostIFoodMarketConfigRequest } from 'modules/config/integrationsConfig/domain/dto/iFoodMarketConfig/IPostIFoodMarketConfigRequest';
import { IPostIFoodMarketConfigResponse } from 'modules/config/integrationsConfig/domain/dto/iFoodMarketConfig/IPostIFoodMarketConfigResponse';
import { IGetReceitaFederalResponse } from 'modules/config/integrationsConfig/domain/dto/IGetReceitaFederalResponse';
import { IGetHubByOwnerIdResponse } from 'modules/config/integrationsConfig/domain/dto/IGetHubByOwnerIdResponse';
import { IPostHubRefreshKeyResponse } from 'modules/config/integrationsConfig/domain/dto/IPostHubRefreshKeyResponse';
import { IGetHubByKeyResponse } from 'modules/config/integrationsConfig/domain/dto/IGetHubByKeyResponse';
import { IPostHubToggleActivationRequest } from 'modules/config/integrationsConfig/domain/dto/IPostHubToggleActivationRequest';
import { IPostHubToggleStatusRequest } from 'modules/config/integrationsConfig/domain/dto/IPostHubToggleStatusRequest';

const IntegrationApi = (): IIntegrationService => {
    const verifyOrganizer = async (localId: string): Promise<IGetVerifyOrganizerResponse> => {
        const response = await api.get<IGetVerifyOrganizerResponse>(`/MeepTickets/VerifyOrganizer/${localId}`);
        return (response.data);
    }

    const getOrganizerByDocument = async (localId: string): Promise<IGetOrganizerByDocumentResponse> => {
        const response = await api.get<IGetOrganizerByDocumentResponse>(`/MeepTickets/GetOrganizer/${localId}`, { params: { disableError: true } });
        return (response.data);
    }

    const integrateOrganizer = async (localId: string): Promise<void> => {
        await api.post(`/MeepTickets/IntegrateOrganizer/${localId}`);
    }

    const getIfoodStatusConfiguration = async (localId: string): Promise<IGetStatusIfoodConfigurationResponse> => {
        const respomse = await api.get<IGetStatusIfoodConfigurationResponse>("/ifoodMerchantConfiguration/" + localId);
        return respomse.data;
    }

    const postIfoodStatusConfiguration = async (request: IIFoodStatusConfigRequest): Promise<IIFoodStatusConfigResponse> => {
        const respomse = await api.post<IIFoodStatusConfigResponse>("/ifoodMerchantConfiguration/", request);
        return respomse.data;
    }

    const getIfoodMarketConfiguration = async (localId: string): Promise<IPostIFoodMarketConfigResponse> => {
        const respomse = await api.get<IPostIFoodMarketConfigResponse>(`/ifoodConfiguration/${localId}`);
        return respomse.data;
    }

    const postIfoodMarketConfiguration = async (request: IPostIFoodMarketConfigRequest): Promise<IPostIFoodMarketConfigResponse> => {
        const respomse = await api.post<IPostIFoodMarketConfigResponse>("/ifoodConfiguration/", request);
        return respomse.data;
    }

    const getSankhyaConfiguration = async (localId: string): Promise<IGetSankhyaConfigurationResponse> => {
        const response = await api.get<IGetSankhyaConfigurationResponse>(`/sankhya/${localId}`);
        return (response.data);
    }

    const postSankhyaPayment = async (request: ISankhyaPaymentRequest): Promise<ISankhyaPaymentResponse | void> => {
        const respomse = await api.post<IIFoodStatusConfigResponse>("/sankhya/PaymentType/Create/", request);
        return respomse.data;
    }

    const getSankhyaPaymentForm = async (localId: string): Promise<IGetSankhyaPaymentFormResponse[]> => {
        const response = await api.get<IGetSankhyaPaymentFormResponse[]>("/FormaPagamento/FindByFilter", { params: { LocalClienteId: localId }});
        return (response.data);
    }

    const postSankhyaConfiguration = async (request: ISankhyaConfigurationRequest): Promise<ISankhyaConfigurationResponse | void> => {
        const respomse = await api.post<IIFoodStatusConfigResponse>("/sankhya/", request);
        return respomse.data;
    }

    const deleteSankhyaPayment = async (paymentTypeId: string): Promise<void> => {
        const respomse = await api.delete<void>(`/sankhya/PaymentType/Delete/${paymentTypeId}`);
        return respomse.data;
    }

    const deleteContractReceitaFederal = async (localId: string): Promise<any> => {
        const respomse = await api.delete<any>(`/integrations/receita-federal/${localId}`);
        return respomse.data;
    }

    const getFacebookConfiguration = async (localId: string): Promise<string> => {
        const respomse = await api.get<string>("/Proprietario/pixelfacebook", { params: { LocalClienteId: localId }});
        return respomse.data;
    }

    const postFacebookConfiguration = async (request: IPostFacebookConfigRequest): Promise<void> => {
        const respomse = await api.post<void>("/Proprietario/pixelfacebook", request);
        return respomse.data;
    }

    const getMobiConfiguration = async (localId: string): Promise<IGetMobiConfigResponse> => {
        const respomse = await api.get<IGetMobiConfigResponse>("/Proprietario/ConfiguracaoMobi", { params: { LocalClienteId: localId }});
        return respomse.data;
    }

    const postMobiConfiguration = async (request: IPostMobiConfigRequest): Promise<void> => {
        const respomse = await api.post<void>("/Proprietario/ConfiguracaoMobi", request);
        return respomse.data;
    }
    const postReceitaFederal = async (localId: string): Promise<any> => {
        const respomse = await api.post<any>(`/integrations/receita-federal/${localId}`);
        return respomse.data;
    }

    const getLocalMainData = async (localId: string): Promise<IGetLocalMainDataResponse> => {
        const response = await api.get(`/Local/GetLocalMainData/${localId}`);
        return response.data;
    }
    const getReceitaFederal = async (localId: string): Promise<IGetReceitaFederalResponse> => {
        const response = await api.get(`/integrations/receita-federal/${localId}`);
        return response.data;
    }

    const getHubByOwnerId = async (localId: string): Promise<IGetHubByOwnerIdResponse> => {
        const response = await api.get<IGetHubByOwnerIdResponse>(`/Hub/GetKeyByOwnerId/${localId}`);
        return response.data;
    }

    const getHubByKey = async (key: string): Promise<IGetHubByKeyResponse> => {
        const response = await api.get<IGetHubByKeyResponse>(`/Hub/GetOwnerByKey/${key}`);
        return response.data;
    }

    const postHubRefreshKey = async (localId: string): Promise<IPostHubRefreshKeyResponse> => {
        const response = await api.post<IPostHubRefreshKeyResponse>(`/Hub/RefreshKey`, { ownerId: localId });
        return response.data;
    }

    const postHubToggleStatus = async (request: IPostHubToggleStatusRequest): Promise<void> => {
        const response = await api.post(`/Hub/ToggleStatus`, { key: request.key, status: request.status });
        return response.data;
    }

    const postHubToggleActivation = async (request: IPostHubToggleActivationRequest): Promise<void> => {
        const response = await api.post(`/Hub/ToggleActivation`, { ownerId: request.localId, enabled: request.enabled });
        return response.data;
    }

    return {
        verifyOrganizer,
        getOrganizerByDocument,
        integrateOrganizer,
        getIfoodStatusConfiguration,
        postIfoodStatusConfiguration,
        getIfoodMarketConfiguration,
        postIfoodMarketConfiguration,
        getSankhyaConfiguration,
        postSankhyaPayment,
        deleteContractReceitaFederal,
        getSankhyaPaymentForm,
        postSankhyaConfiguration,
        getFacebookConfiguration,
        postFacebookConfiguration,
        getMobiConfiguration,
        getReceitaFederal,
        postMobiConfiguration,
        postReceitaFederal,
        deleteSankhyaPayment,
        getLocalMainData,
        getHubByOwnerId,
        getHubByKey,
        postHubRefreshKey,
        postHubToggleStatus,
        postHubToggleActivation
    }
}

export default IntegrationApi
