import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { IGetDataParams } from '../../interfaces/IGetDataParams'
import RankingList from '../rakingList/RankingList'
import { IRankinProduct, IRakingProductFilter, IProductRankingData, sortByEnum, groupByEnum } from './IRankinProduct'
import styles from './RakingProducts.module.scss'
import './RakingProducts.css'
import { useError } from '../../contexts/error/ErrorContext'
import { dashboardContainerEnum } from '../../interfaces/DashboardContainerEnum'
import SubItemsProducts from './subItemProducts/SubItemsProducts'

export interface IRakingProductsProps {
    //propertys
    defaultProductList?: IRankinProduct[],
    getProductList: ({ page, pageSize, sortField, sortOrientation, groupBy }: IGetDataParams, productGroupId? :string) => Promise<IProductRankingData>,
    isMobile?: boolean

}
const RakingProducts: FC<IRakingProductsProps> = ({ getProductList, defaultProductList, isMobile }) => {
    const [productList, setproductList] = useState<IRankinProduct[] | undefined>(defaultProductList);
    const [count, setCount] = useState(50);
    const [onLoading, setOnLoading] = useState(false);
    const filterProductRanking: IRakingProductFilter = {
        sortBy: sortByEnum.total,
        groupBy: groupByEnum.None,
        sortOrientation: 'desc'
    };
    const { setOnError, clearContainerError } = useError();
    const [currentPage, setCurrentPage] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    const getData = useCallback(async (page?: number) => {
        try {
            clearContainerError(dashboardContainerEnum.RANKING_PRODUCTS);
            !page && setOnLoading(true);
            const response = await getProductList?.({ page, sortField: filterProductRanking.sortBy, groupBy: filterProductRanking.groupBy, sortOrientation: filterProductRanking.sortOrientation });

            if (page) {
                setproductList(prev => ([...(prev ?? []), ...response.records]));
                setCount(response.totalRecords);
            } else {
                setproductList(prev => ([...response.records]));
                setCount(response.totalRecords);
            }

            if (!page && !response.records.length) {
                setOnError({
                    type: 'warning',
                    containerId: dashboardContainerEnum.RANKING_PRODUCTS
                })
            }
        } catch (error: any) {
            setOnError({
                type: 'error',
                containerId: dashboardContainerEnum.RANKING_PRODUCTS
            })
        } finally {
            setOnLoading(false);
        }
    }, [clearContainerError, filterProductRanking.groupBy, filterProductRanking.sortBy, filterProductRanking.sortOrientation, getProductList, setOnError]);

    useEffect(() => {
        getData(0);
    }, [getData]);

    const getMore = useCallback(() => {
        setCurrentPage(currentPage + 1);
        getData(currentPage + 1);
    }, [currentPage, getData]);

    return (
        (<div ref={containerRef} id={styles.RakingProducts}>

            <div className={styles.container}>
                {
                    <RankingList
                        list={productList}
                        getMore={getMore}
                        isLoading={onLoading}
                        count={count}
                        colunms={{
                            name: { title: "Nome" },
                            categoryName: { title: "Categoria", hide: isMobile },
                            quantity: { title: isMobile ? "Qtd" : "Quantidade" },
                            total: { title: "Valor" }
                        }}

                        accordeon={
                            filterProductRanking.groupBy ? 
                            ((productItem) => <SubItemsProducts groupBy={filterProductRanking.groupBy} getSubItems={getProductList} categoryId={productItem.id!} />) : 
                            undefined
                        }
                    />
                }
            </div>
        </div>)
    )
}
export default RakingProducts