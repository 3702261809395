import React, { useCallback, useMemo, useState } from 'react'
import styles from './ConsultDocumentsItemMobile.module.scss'
import CPF from 'services/utils/CPF';
import CNPJ from 'services/utils/CNPJ';
import { moneyMaskNumber } from 'services/utils/Money';
import { IConsultDocumentsList, IRecordsItem } from '../../../../interfaces/IConsultDocumentsList';
import { IParamsConsultDocumentsList } from '../../../../hooks/UseConsultDocumentsList';
import Utils from 'services/utils/Utils';

interface IPermutationAccountListMobileProps {
    userItemData: IRecordsItem;
    consultDocumentsList: IConsultDocumentsList;
    // reload: () => void;
    params: IParamsConsultDocumentsList;
    // getPermutationAccountItem: (accountId: string) => Promise<IGetPermutionItemResponse | undefined>
    // setOpenSidesheet: React.Dispatch<React.SetStateAction<"isNew" | "isEditing" | "isDeleting" | null>>
}

export const ConsultDocumentsItemMobile: React.FC<IPermutationAccountListMobileProps> = ({ userItemData }) => {
 
   

    const formatDocument = useCallback((document: string) => {
        if (CPF.isValid(document))
            return CPF.cpfMask(document);
        else
            return CNPJ.mask(document);
    }, []);

    const List = useMemo(() => (
        <div className={styles.containerItem}>
            <div className={styles.top}>
                <span>CPF consultado:</span>
                <span>***.***.*{formatDocument(userItemData.document)}</span>
            </div>
            <div className={styles.mid}>
                <span>Data e hora:</span>
                <span>{Utils.toDateAndTime(userItemData.date)}</span>            
            </div>
            <div className={styles.mid}>
                <span>Usuário:</span>
                <span>{userItemData.user}</span>            
            </div>
            <div className={styles.mid}>
                <span>Email:</span>
                <span>{userItemData.email}</span>            
            </div>
            <div className={styles.mid}>
                <span>Dispositivo:</span>
                <span>{userItemData.device}</span>            
            </div>
            <div className={styles.footer}>
                <span>Custo por consulta:</span>
                <span>{moneyMaskNumber(userItemData.cost)}</span>
            </div>
        </div>
    ), [formatDocument, userItemData.cost, userItemData.date, userItemData.device, userItemData.document, userItemData.email, userItemData.user]);

    return (
        <div id={styles.ConsultDocumentsItemMobile}>
            {List}           
        </div>
    )
}
