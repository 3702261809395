import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import UseDimension from "components/dimension/UseDimension";
import styles from './ExportValidation.module.scss';

interface ExportValidationProps {
  text: string;
  highlightText?: string;
  changeSelectExportType: (exportType: string) => void
}

const ExportValidation = ({ text, highlightText, changeSelectExportType }: ExportValidationProps) => {
  const { isMobile } = UseDimension();

  return (

    <div id={styles.ExportValidation}>
      <div className={styles.text}>
        <span>{text}</span> <b>{highlightText}</b>
      </div>
      <div className={styles.containerRadio}>
        <RadioGroup
          onChange={(_, value) => changeSelectExportType(value)}
          aria-labelledby="demo-radio-buttons-group-label"
        >
          <div className={styles.content}>
            <div>
              <FormControlLabel
                value={"EXCELOPENXML"}
                control={<Radio />}
                label="XLS"
              />
            </div>
            <div>
              <FormControlLabel
                value={"PDF"}
                control={<Radio />}
                label="PDF"
              />
            </div>
          </div>
        </RadioGroup>
      </div>
    </div>

  );
};

export default ExportValidation;
