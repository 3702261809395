import { api } from "../Api";
import { ISort } from "interfaces/ISort";
import { IGetConsultDocumentsParams } from "modules/config/integrationsConfig/presentation/pages/configReceitaFederal/consultDocuments/domain/dto/IGetConsultDocumentsParams";
import { IGetConsultDocumentsResponse } from "modules/config/integrationsConfig/presentation/pages/configReceitaFederal/consultDocuments/domain/dto/IGetConsultDocumentsResponse";
import { IConsultDocumentsApiService } from "modules/config/integrationsConfig/presentation/pages/configReceitaFederal/consultDocuments/domain/interfaces/IPermutationApiService";

const ConsultDocumentsApi = (): IConsultDocumentsApiService => {
  const getConsultDocuments = async (localId: string, params?: IGetConsultDocumentsParams, sort?: ISort): Promise<IGetConsultDocumentsResponse> => {
    const data = {
      ...params,
      "sort.orientation": sort?.orientation,
      "sort.type": sort?.type,
    }

    const response = await api.get(`integrations/receita-federal/${localId}/report`, { params: data });
    return response.data;
  };

  const postConsultDocumentsExport = async (localId: string, params?: IGetConsultDocumentsParams, sort?: ISort): Promise<IGetConsultDocumentsResponse> => {
    const data = {
      ...params,
      "sort.orientation": sort?.orientation,
      "sort.type": sort?.type,
    }

    const response = await api.post(`integrations/receita-federal/${localId}/report/export`, data);
    return response.data;
  };


  return { getConsultDocuments, postConsultDocumentsExport };
}

export default ConsultDocumentsApi;
