import Button from "components/ui/Button/Button";
import React, { FC, useCallback, useState } from "react";
import style from "./EventCard.module.scss";
import UseEventCard from "./UseEventCard";
import { IconButton, Icon } from "@material-ui/core";
import { EventStatusEnum, IEvents } from "modules/events/domain/models/IEvents";
import guestIcon from "../../../../assets/icons/guests_icon_outlined.svg";
import listIcon from "../../../../assets/icons/list_icon_outlined.svg";
import { useAuth } from "modules/auth/presentation/context/AuthContext";
import Utils from "services/utils/Utils";
import { EventLink } from "../eventLink/EventLink";
import { Tooltip } from "@mui/material";
import { InfoOutlined, LockOutlined } from "@mui/icons-material";
import UseDimension from "components/dimension/UseDimension";
import SidesheetAddEvent, { IColaborador } from "../sidesheetAddEvent/SidesheetAddEvent";
import { UseAddColaborador } from "modules/events/presentation/pages/UseAddColaborador";

export interface IEventCardResume {
  eventId: string,
  billing: number;
  averageConsumption: number;
  averageTickets: number;
  generalTicketSold: number;
  generalTicketCheckins: number;
  guests: number;
  guestsTicketsSold: number;
  guestsCheckIns: number;
  meepTicketsSold: number;
  meepTicketsCheckins: number;
  totalSold: number;
  totalCheckins: number;
}

export interface IEventCardProps {
  event: IEvents;
  resume?: IEventCardResume
  integrated: boolean
}

const EventCard: FC<IEventCardProps> = ({ event, resume, integrated }) => {
  const {
    goToEventList,
    goToPeopleList,
    adminAccess,
    goToManageEvent,
    linkOpened,
    setLinkOpened,
    getOrganizerEvents,
    organizerEvents,
    onIntegrateMeepTickets
  } = UseEventCard();
  const [hasLink, setHasLink] = useState(!!event.ioticketEventId)
  const [openAddEvent, setOpenAddEvent] = useState<string | null>(null)
  const [tooltipGeneral, setTooltipGeneral] = useState(false);
  const [tooltipTotal, setTooltipTotal] = useState(false);
  const { getEventsAllowedUsers, eventsAllowedUsers, postEventsAllowedUsers, deleteEventsAllowedUsers, getLocalUsers, localUsers } = UseAddColaborador()

  const { hasAccessRole } = useAuth();
  const { dimensions } = UseDimension()

  const onAccessEvent = async (eventId: string) => {
    setOpenAddEvent('add')
    getEventsAllowedUsers(eventId)
    getLocalUsers(eventId)
  }

  const handleDelete = (id: string) => {
    deleteEventsAllowedUsers(event.id, id)
  }

  const isHappening = (evt: IEvents): EventStatusEnum => {
    const now = new Date();
    const begin = new Date(evt.openingDates[0].startDate);
    const end = new Date(evt.openingDates[0].endDate);
    return now > begin && now <= end
      ? EventStatusEnum.HAPPENING
      : now > end
        ? EventStatusEnum.CLOSED
        : EventStatusEnum.SCHEDULED;
  };

  const happeningComponent = useCallback((evt: IEvents) => {
    const eventStatus = isHappening(evt);
    switch (eventStatus) {
      case EventStatusEnum.SCHEDULED:
        return (
          <div className={style.status}>
            <div className={style.icon} style={{ background: 'var(--positive)' }}></div>
            <p>Programado</p>
          </div>
        );
      case EventStatusEnum.CLOSED:
        return (
          <div className={style.status}>
            <div className={style.icon} style={{ background: 'var(--placeholder)' }}></div>
            <p>Encerrado</p>
          </div>
        );
      case EventStatusEnum.HAPPENING:
        return (
          <div className={style.status}>
            <div className={style.icon} style={{ background: 'var(--secondary-dark)' }}></div>
            <p>Acontecendo agora</p>
          </div>
        );
    }
  }, []);

  const onCloseLink = useCallback((success?: boolean) => {
    if (success) {
      setHasLink(true)
    }
    setLinkOpened(false)
  }, [setLinkOpened])

  const handleSubmit = async (colaboradores: IColaborador[]) => {
    const body = colaboradores.map(item => item.id)
    try {
      await postEventsAllowedUsers(event.id, body)
      setOpenAddEvent(null)
    } catch (error) {
      console.error('Ocorreu um erro ao enviar os colaboradores:', error);
    }
  }

  return (
    <div id={style.EventCard}>
      <div className={style.boxInicial}>
        <div className={style.img}>
          <img src={event.imageUrl} alt="event_image" />
        </div>
        <div className={style.content}>
          <div className={style.title}>
            <div className={style.start}>
              <h2>
                <b>{event.name} - </b>
                {event?.openingDates[0].startDate && Utils.toDateAndTime(event?.openingDates[0].startDate)}
                {event?.openingDates[0].endDate ? ` até ${Utils.toDateAndTime(event?.openingDates[0].endDate)}` : ""}
              </h2>
            </div>
            <div className={style.end}>
              {happeningComponent(event)}
              <IconButton
                className={style.iconButton}
                title="Lista de convidados"
                onClick={() => goToEventList(event.id, event.name)}
              >
                <img src={listIcon} alt="list_icon" />
              </IconButton>
              {hasAccessRole("AdicionarColaboradoresEventos") && (
                <IconButton
                  className={style.iconButton}
                  title="Acesso a evento"
                  onClick={() => onAccessEvent(event.id)}
                >
                  <LockOutlined />
                </IconButton>
              )}
              <IconButton
                onClick={() => goToPeopleList(event.id, event.name)}
                title="Convidados"
                className={style.iconButton}
              >
                <img src={guestIcon} alt="guests_icon" />
              </IconButton>
            </div>
          </div>
          {(hasLink || (!hasLink && dimensions.width > 900)) &&
            <div className={style.description}>
              <div className={style.body}>
                <div className={style.ioTicketsEvent}>
                  {
                    integrated && <>
                      <button type={'button'} onClick={() => setLinkOpened(true)}>
                        <div className={style.icon} style={{ background: hasLink ? 'var(--positive)' : 'var(--placeholder)' }}></div>
                        <p>
                          {hasLink ? 'Vinculado à Meep Tickets' : 'Não Vinculado à Meep Tickets'}
                        </p>
                        <Icon className={style.rightIcon}>chevron_right</Icon>
                      </button>
                    </>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <div className={style.boxMeio}>
        <div className={style.boxDestaque}>
          {hasAccessRole("DashboardPosPago") && <p>Faturamento: <b>{resume ? formatCurrency(resume.billing) : 'R$0,00'}</b></p>}
          <p>Ticket médio (Consumo): <b>{resume ? formatCurrency(resume.averageConsumption) : 'R$0,00'}</b></p>
          <p>Ticket médio (MeepTickets + entrada): <b>{resume ? formatCurrency(resume.averageTickets) : 'R$0,00'}</b></p>
        </div>
        <div className={style.manageButton}>
          <Button
            variant="outlined"
            style={{ color: 'white', backgroundColor: '#955CFF', border: 'none', }}
            fullWidth={false}
            onClick={() => goToManageEvent(event.id)}
            disabled={!adminAccess}
          >
            Entrar
          </Button>
        </div>
      </div>
      <div className={style.boxFinal}>
        <div className={style.contentFinal}>
          <div className={style.contentTitle}>
            <p className={style.title}>Entrada geral</p>
            <Tooltip
              title="Vendas realizadas na portaria do evento para clientes sem nome na lista + vendas nome na lists"
              open={tooltipGeneral}
            >
              <IconButton onClick={() => setTooltipGeneral((prevOpen) => !prevOpen)}>
                <InfoOutlined />
              </IconButton>
            </Tooltip>
          </div>
          <p>Entradas vendidas: <span>{resume ? resume.generalTicketSold : '0'}</span></p>
          <p>Check-in realizados: <span>{resume ? resume.generalTicketCheckins : '0'}</span></p>
        </div>
        <div className={style.contentFinal}>
          <p className={style.title}>Nome na lista</p>
          <p>Convidados: <span>{resume ? resume.guests : '0'}</span></p>
          <p>Entradas vendidas: <span>{resume ? resume.guestsTicketsSold : '0'}</span></p>
          <p>Check-in realizados: <span>{resume ? resume.guestsCheckIns : '0'}</span></p>
        </div>
        {hasLink &&
          <div className={style.contentFinal}>
            <p className={style.title}>MeepTickets</p>
            <p>Ingressos vendidos: <span>{resume ? resume.meepTicketsSold : '0'}</span></p>
            <p>Check-in realizados: <span>{resume ? resume.meepTicketsCheckins : '0'}</span></p>
          </div>
        }
        <div className={style.contentFinal}>
          <div className={style.contentTitle}>
            <p className={style.title}>Total</p>
            <Tooltip title="(MeepTickets + entrada geral)" open={tooltipTotal}>
              <IconButton onClick={() => setTooltipTotal((prevOpen) => !prevOpen)}>
                <InfoOutlined />
              </IconButton>
            </Tooltip>
          </div>
          <p>Vendidos: <span>{resume ? resume.totalSold : '0'}</span></p>
          <p>Check-in realizados: <span>{resume ? resume.totalCheckins : 0}</span></p>
        </div>
      </div>
      <EventLink
        event={event}
        events={organizerEvents}
        getData={getOrganizerEvents}
        onSubmit={onIntegrateMeepTickets}
        open={linkOpened}
        onClose={onCloseLink}
      />
      <SidesheetAddEvent
        openAddEvent={openAddEvent}
        setOpenAddEvent={setOpenAddEvent}
        allowedUsers={eventsAllowedUsers}
        accessUsers={localUsers}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
      />
    </div>
  );
};
export default EventCard;

const formatCurrency = (value: number) => new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
}).format(value);
