import { IGetProductsListRequest } from "modules/products/domain/dto/IGetProductsListRequest";
import { IProduct } from "models/product/IProduct";
import { api } from "../Api";
import { parseObject } from "./ParseProduct";
import { IGetProductsListResponse, IGetSimpleProductsListResponse } from "modules/products/domain/dto/IGetProductsListResponse";
import ObterListaDeProdutos from "./antiCorruptionProductsService/ObterListaDeProdutos";
import ObterCategoriasDeProdutos from "./antiCorruptionProductsService/ObterCategoriasDeProdutos";
import { IProductListResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/getProductListList/IGetProductListListResponse";
import ObterLojas from "./antiCorruptionProductsService/ObterLojas";
import { IGetLojasResponse } from "modules/products/domain/dto/IGetLojasResponse";
import { IGetImpressoraPadraoResponse } from "modules/products/domain/dto/IGetImpressoraPadraoResponse";
import ObterImpressoras from "./antiCorruptionProductsService/ObterImpressoras";
import ExcluirProdutosEmMassa from "./antiCorruptionProductsService/ExcluirProdutosEmMassa";
import AlteraImpressoraPadrao from "./antiCorruptionProductsService/AlteraImpressoraPadrao";
import AlterarLojaEmMassa from "./antiCorruptionProductsService/AlterarLojaEmMassa";
import AlterarDadosFiscaisEmMassa from "./antiCorruptionProductsService/AlterarDadosFiscaisEmMassa";
import AlterarControleEstoque from "./antiCorruptionProductsService/AlterarControleEstoque";
import { IPostImportarProdutos } from "modules/products/domain/dto/IPostImportarProdutos";
import { IGetExportProductListRequest } from "modules/products/domain/dto/IGetExportProductListRequest";
import { IGetExportProductListResponse } from "modules/products/domain/dto/IGetExportProductListResponse";
import { IGetLocalResumeConfigurationResponse } from "modules/local/domain/dtos/IGetLocalResumeResponse";
import { IGetSplitLocalsResponse } from "modules/products/domain/dto/IGetSplitLocalsResponse";
import ObterListaSimplesDeProdutos from "./antiCorruptionProductsService/ObterListaSimplesDeProdutos";
import { IProductWithoutSplitResponse } from "modules/products/domain/dto/IProductWithoutSplitResponse";

export const ProductsApi = () => {
  const getProductsList = async (
    request: IGetProductsListRequest
  ): Promise<IGetProductsListResponse> => {
    return ObterListaDeProdutos(api, request);
  };

  const getSimpleProductsList = async (
    request: IGetProductsListRequest
  ): Promise<IGetSimpleProductsListResponse> => {
    return ObterListaSimplesDeProdutos(api, request);
  };

  const getCategoriesList = async (request: {
    localId: string;
  }): Promise<{ name: string; id: string }[]> => {
    return ObterCategoriasDeProdutos(api, request.localId);
  };

  const getLojasList = async (request: {
    localId: string;
  }): Promise<IGetLojasResponse> => {
    return ObterLojas(api, request.localId);
  };

  const getImpressoraPadraoList = async (request: {
    localId: string;
  }): Promise<IGetImpressoraPadraoResponse> => {
    return ObterImpressoras(api, request.localId);
  };

  const bulkDeleteProducts = async (productIds: string[], shared: boolean): Promise<void> => {
    return ExcluirProdutosEmMassa(api, productIds, shared);
  };

  const changeTaxData = async (body: { productIds: string[]; ncm: string; cest: string; cfop: string; }, shared?: boolean): Promise<void> => {
    return AlterarDadosFiscaisEmMassa(api, body, shared);
  };


  const changeDefaultPrinter = async (
    productId: string,
    defaultPrinterId?: string
  ): Promise<void> => {
    return AlteraImpressoraPadrao(api, productId, defaultPrinterId);
  };

  const changeStockControl = async (
    productId: string,
    stockControl: boolean
  ): Promise<void> => {
    return AlterarControleEstoque(api, productId, stockControl);
  };

  const changeStore = async (
    productId: string,
    storeId: string
  ): Promise<void> => {
    return AlterarLojaEmMassa(api, productId, storeId);
  };

  const editProduct = async (
    request: IProduct,
    eventId: string
  ): Promise<void> => {
    const productToEdit = parseObject(request, eventId);
    await api.post(`/Produto/EditarProduto`, productToEdit);
  };

  const copyProduct = async (productId: string): Promise<void> => {
    await api.post(` {{env}}/api/Catalog/Local/{localId}/DuplicateProduct/{productId}`, { id: productId });
    // await api.post(`/Produto/duplicarproduto`, { id: productId });
  };

  const activateProduct = async (productId: string): Promise<void> => {
    await api.post(`/Proprietario/AtivarProdutos/${productId}`);
  };

  const disableProduct = async (productId: string): Promise<void> => {
    await api.post(`/Proprietario/DesativarProdutos/${productId}`);
  };

  const deleteProduct = async (productId: string): Promise<void> => {
    await api.post(`/Produto/RemoverProduto/${productId}`);
  };

  const postExportProductRearguard = async (localId: string): Promise<void> => {
    await api.post('/Produto/ExportarParaRetaguarda', { id: localId });
  };

  const getSplitLocals = async (localId: string): Promise<IGetSplitLocalsResponse[]> => {
    const response = await api.get(`/Produto/BuscarLocaisSplit?localId=${localId}`);
    return response.data
  };

  const getProductListListByLocalId = async (
    localId: string
  ): Promise<IProductListResponse[]> => {
    const response = await api.get(
      `Proprietario/BuscarListasProdutosDoLocal/${localId}`
    );
    return response.data;
  };

  const getExportProductList = async (localId: string, request: IGetExportProductListRequest): Promise<IGetExportProductListResponse> => {
    const params = {
      keyword: request.keyword,
      localId: localId,
      Extensao: request.extension,
      categoriasIds: request.categoriesIds,
    }

    const response = await api.get<IGetExportProductListResponse>('/Produtos/RelatorioExcel', { params })

    return response.data;
  };

  const ImportProduct = async ({
    localId,
    conteudo,
  }: IPostImportarProdutos) => {
    return await api.post(`/importarproduto`, {
      localClienteId: localId,
      conteudo,
    });
  };

  const getLocalConfiguration = async (localId: string): Promise<IGetLocalResumeConfigurationResponse> => {
    const response = await api.get(`/Proprietario/BuscarConfiguracoesDoLocal/${localId}`);
    const config = response.data.configuracaoSistemasGestao.filter((x: { tipoSistema: { descricao: string, id: number } }) => x.tipoSistema.id === 2);
    const result: IGetLocalResumeConfigurationResponse = {
        cashlessPrepaid: (config.length > 0),       
        gerarNFCE: response.data.gerarNFCE,
        podeExportarProdutos: response.data.podeExportarProdutos
    };
 
    return result;
  };

  const getProductsWithoutSplit = async (localId: string): Promise<IProductWithoutSplitResponse> => {
    const response = await api.get("Produto/BuscarProdutosSemSplit/" + localId)   
    return response.data
  };

  return {
    getProductsList,
    getCategoriesList,
    activateProduct,
    disableProduct,
    editProduct,
    deleteProduct,
    getProductListListByLocalId,
    getLojasList,
    getImpressoraPadraoList,
    bulkDeleteProducts,
    changeDefaultPrinter,
    changeStore,
    changeTaxData,
    changeStockControl,
    copyProduct,
    ImportProduct,
    getExportProductList,
    postExportProductRearguard,
    getLocalConfiguration,
    getSplitLocals,
    getSimpleProductsList,
    getProductsWithoutSplit,
  };
};
