import styles from "components/sidesheet/Sidesheet.module.scss";
import { IGetReceitaFederalResponse } from "modules/config/integrationsConfig/domain/dto/IGetReceitaFederalResponse";
import Utils from "services/utils/Utils";

export interface IConfigContentReceitaFederalProps {
  values: IGetReceitaFederalResponse | undefined
}

const ConfigContentReceitaFederal: React.FC<IConfigContentReceitaFederalProps> = ({ values }) => {
  return (
    <div className={styles.configContainer}>
      <p><b>TERMOS E CONDIÇÕES PARA INTEGRAÇÃO COM INTERFACE DE PROGRAMAÇÃO DE APLICAÇÕES (“API”) DA RECEITA FEDERAL DO BRASIL (“RFB”)</b></p>

      <p><b>{values?.localName}</b>, pessoa jurídica de direito privado, inscrita no Cadastro Nacional de Pessoas Jurídicas sob o nº <b>{values?.document}</b>, e com sede na <b>{values?.address}</b> (“CLIENTE”) <b>adere</b> às cláusulas e condições gerais previstas nestes <b>Termos e Condições para Integração com Interface de Programação de Aplicações (“API”) da Receita Federal do Brasil (“RFB”)</b> (“Termo”), junto à MEEP SOLUÇÕES S.At., sociedade anônima fechada, inscrita no Cadastro Nacional de Pessoas Jurídicas sob o nº 28.302.604/0001-01, e com sede na Rua da Bahia, nº 888, 5º Andar, Bairro Centro, no Município de Belo Horizonte, no Estado de Minas Gerais, CEP 30.160-011, neste ato representada na forma dos seus atos societários (“MEEP”), MEEP e CLIENTE designados, quando em conjunto, “Partes”, e, quando, individualmente “Parte”.</p>

      <p>As condições gerais previstas neste Termo são complementares e de nenhuma forma limitam, restringem ou anulam quaisquer direitos e obrigações previstos em outros termos e/ou contratos que venham a ser celebrados entre as Partes.</p>

      <p>Para a utilização de outros produtos ou serviços oferecidos pela MEEP poderá ser exigido do CLIENTE a celebração de termos e/ou contratos específicos, cujas disposições prevalecerão em caso de conflito com as condições gerais estabelecidas neste Termo.</p>

      <h4>ACEITE AUTOMÁTICO</h4>

      <ul>
        <li>1.1 Ao aceitar eletronicamente este Termo, com a marcação da caixa de diálogo “Li e concordo com os Termos e Condições para Integração com Interface de Programação de Aplicações (“API”) da Receita Federal do Brasil (“RFB”)”, o CLIENTE estará automaticamente aderindo e concordando com as condições deste Termo e das demais políticas vigentes no âmbito da MEEP.</li>
        <li>1.2 A MEEP poderá, periodicamente, alterar as condições deste Termo, ao seu exclusivo critério, podendo o CLIENTE, caso não concorde com a modificação, denunciá-lo, sem quaisquer ônus ou penalidades.</li>
      </ul>

      <h4>OBJETO</h4>

      <ul>
        <li>2.1 Como objeto deste Termo, as Partes têm entre si ajustada e contratada a prestação de serviço, pela MEEP ao CLIENTE, de consulta de dados disponibilizados pela RFB através de software de pagamento de desenvolvimento e titularidade da MEEP (“Plataforma”), mediante integração da Plataforma com API da RFB.</li>
        <li>2.1.1 Os dados disponibilizados pela RFB e que poderão ser consultados através da Plataforma mediante a integração da Plataforma com a API da RFB são os seguintes: nome, data de nascimento, nome da mãe e sexo.</li>
        <li>2.2 Pelo objeto deste Termo, o CLIENTE pagará, à MEEP, o valor de R$0,10 (dez centavos) por consulta, valor que será incluído na fatura mensal a ser emitida pela MEEP em face ao CLIENTE pelos serviços prestados pela MEEP em favor do CLIENTE.</li>
      </ul>

      <h4>DECLARAÇÕES E GARANTIAS</h4>
      <ul>
        <li>3.1 A MEEP e o CLIENTE declaram que seguem os ditames da Lei nº 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados Pessoais ou “LGPD”) no tratamento dos dados pessoais.</li>
        <li>3.2 A MEEP e o CLIENTE declaram que utilizarão todo e qualquer dado obtido por meio do objeto deste Termo apenas para os fins acordados, quais sejam facilitar o processo de identificação de clientes através do CPF, conforme autorizado pela LGPD, no que também concordam em não compartilhar ou divulgar qualquer dos referidos dados sem o consentimento expresso da pessoa física à qual os dados se refiram.</li>
      </ul>

      <h4>DISPOSIÇÕES GERAIS E FORO</h4>
      <ul>
        <li>4.1 O CLIENTE declara integral anuência a todo o disposto neste Termo, obrigando as partes de forma irrevogável, inequívoca e irretratável.</li>
        <li>4.2 Os vínculos jurídicos que decorrem do aceite dos termos e condições deste instrumento dispensam a formalização de outro documento específico, estando concordes as Partes com todos os termos e condições deste Termo valerão para elas como negócio jurídico perfeito e acabado, devendo produzir, de imediato, os seus efeitos jurídicos.</li>
        <li>4.3 Fica eleito o foro da Comarca de Belo Horizonte/MG, para conhecer das questões que se originarem deste Termo.</li>
        <li>4.4 As Partes declaram-se cientes de que o Termo é firmado na modalidade click-wrap agreement, reconhecendo-o, de maneira irretratável, irrevogável e inequívoca, como plenamente válido e eficaz para todos os fins, sendo a manifestação eletrônica de consentimento e de vontade suficiente para a formação do vínculo contratual e das obrigações mútuas assumidas neste Termo, em consonância com a disciplina do artigo 107 do Código Civil.</li>
      </ul>

      <p><b>{`${values?.localName} - ${Utils.toDateAndTime(values?.acceptedAt ?? '')}`}</b></p>
    </div>
  );
};

export default ConfigContentReceitaFederal;
