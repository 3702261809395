import { ICatalogService } from "modules/catalog/domain/interface/ICatalogService";
import { IAccessProductValues, IAccessUser } from "modules/catalog/presentation/componentes/productActions/accessProductForm/AccessProductForm";

const LimitProductAccessUseCase = async (catalogService: ICatalogService, productId: string, values: IAccessProductValues, shared?: boolean): Promise<void> => {  
    await catalogService.limitProductAccess({
        productId: productId,
        userIds: values.accessUsers?.map(item => item.id) ?? [],
        AllSharedProducts: shared
    });
};

export default LimitProductAccessUseCase