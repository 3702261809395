import React, { FC, FormEvent, Fragment, useCallback, useEffect, useState } from 'react';
import styles from './ProductComplementListForm.module.scss';
import { IComplementProductFormValue } from '../interfaces/IComplementProductFormValue';
import { v4 } from 'uuid';
import ProductComplementItemForm, { IproductComplementError } from './productComplementFormItem/ProductComplementItemForm';
import { Separator } from '../../ui/Separator';
import ButtonsForm from '../../ui/ButtonsForm';
import { CompositionProductType } from 'modules/catalog/domain/enums/CatalogProductEnums';
import Button from 'components/ui/Button/Button';
import { AddCircle } from '@material-ui/icons';
import { ContainerLabel } from '../../ui/ContainerLabel';
import { Autocomplete, Tab, Tabs } from '@mui/material';
import { InputLabel } from '../../ui/InputLabel';
import { ILocalItem, ILocalList } from 'modules/local/presentation/interfaces/ILocalResume';
import { ILocaisSelect } from '../../productForm/IProducFormValuet';

const DEFAULTPRODUCT: IComplementProductFormValue = {
    id: v4(),
    name: "",
    value: 0,
    min: 0,
    max: 5,
    quantity: 1,
    type: CompositionProductType.VARIAVEL,
};

export interface IProductComplementListFormProps {
    defaultValues?: IComplementProductFormValue[];
    onSubmit: (values: IComplementProductFormValue[]) => void;
    onCancel: () => void;
    sharedLocals: ILocaisSelect[] | undefined; // Verifique esta prop
    disabledEditProduct?: boolean;
}

const ProductComplementListForm: FC<IProductComplementListFormProps> = ({
    defaultValues,
    onSubmit,
    onCancel,
    disabledEditProduct,
    sharedLocals, // Verifique esta prop
}) => {
    const [values, setValues] = useState<IComplementProductFormValue[]>(defaultValues ?? []);
    const [errors, setErrors] = useState<{ id: string; error: IproductComplementError }[]>(
        defaultValues?.map((item) => ({ id: item.id, error: {} })) ?? []
    );
    const [search, setSearch] = useState('');
    const [tab, setTab] = useState(0);

    const [filteredLocals, setFilteredLocals] = useState<ILocalItem[]>([]);

    useEffect(() => {
        if (defaultValues) {
            setValues(defaultValues);
        }
    }, [defaultValues]);

    useEffect(() => {
        if (sharedLocals) {
            const mappedLocals = sharedLocals.map((local) => ({
                id: local.id,
                name: local.name,
                imgUrl: local.imgUrl,
                cashierClosing: local.cashierClosing,
                cashierOpening: local.cashierOpening,
            }));
            setFilteredLocals(mappedLocals);
        }
    }, [sharedLocals]);
    
    const onAddComplementProductHandle = useCallback(() => {
        setValues((prev) => [
            ...prev,
            {
                ...DEFAULTPRODUCT,
                id: v4(),
            },
        ]);
    }, []);

    const onChangeComplementProductHandle = useCallback((complementProduct: IComplementProductFormValue) => {
        setValues((prev) => prev.map((item) => (item.id === complementProduct.id ? complementProduct : item)));
    }, []);

    const onDeleteComplementProductHandle = useCallback((complementProduct: IComplementProductFormValue) => {
        setValues((prev) => prev.filter((item) => item.id !== complementProduct.id));
    }, []);

    const validate = useCallback(() => {
        const _errors = values.map<{ id: string; error: IproductComplementError }>((item) => {
            if (item.name.length === 0) {
                return { id: item.id, error: { name: "O campo nome é obrigatário" } };
            } else if (item.value === undefined || item.value === null) {
                return { id: item.id, error: { value: "O campo valor é obrigatário" } };
            } else if (!item.type) {
                return { id: item.id, error: { type: "O campo tipo é obrigatário" } };
            }
            return { id: item.id, error: {} };
        });
        setErrors(_errors);
        if (_errors.some((item) => Object.keys(item.error).length > 0)) {
            return false;
        }
        setErrors(values.map((item) => ({ id: item.id, error: {} })));
        return true;
    }, [values]);

    const changeLocalsHandle = useCallback((index: number, newValues: ILocalItem[] | null) => {
        setValues((prevValues) => {
            const newValuesArray = [...prevValues];
            newValuesArray[index] = { ...newValuesArray[index], sharedLocals: newValues || [] };
            return newValuesArray;
        });
    }, []);

    const submitHandle = useCallback(
        (ev: FormEvent<HTMLFormElement>) => {
            ev.preventDefault();
            if (validate()) {
                onSubmit?.(values);
            }
        },
        [onSubmit, validate, values]
    );

    return (
        <form id={styles.ProductComplementListForm} onSubmit={submitHandle}>
            <div className={styles.container}>
                {values.map((item, index) => (
                    <Fragment key={index}>
                      { sharedLocals && sharedLocals?.length > 0 &&   <div className={styles.inputWidth}>
                            <ContainerLabel>
                                <Autocomplete
                                    fullWidth
                                    renderInput={(params) => (
                                        <InputLabel
                                            label={"Locais:"}
                                            {...params}
                                            onChange={(e) => setSearch(e.target.value)}
                                            placeholder={item.sharedLocals?.length === 0 ? "Selecione os locais para compartilhar o produto" : ''}
                                        />
                                    )}
                                    options={filteredLocals}
                                    getOptionLabel={(option) => option.name}
                                    multiple
                                    value={filteredLocals}
                                    onChange={(_, newValues) => changeLocalsHandle(index, newValues)}
                                    size='small'
                                />
                            </ContainerLabel>
                        </div>}
                        <div>
                            <span style={{ color: "#955CFF", fontWeight: '700' }}>Detalhes do produto</span>
                            <Tabs
                                value={filteredLocals}
                                onChange={(event, newValue) => setTab(newValue)}
                                classes={{
                                    indicator: styles.indicator,
                                    scroller: styles.scroller,
                                    root: styles.root,
                                }}
                            >
                                {filteredLocals?.map((local, idx) => (
                                    <Tab
                                        key={idx}
                                        label={local.name}
                                        classes={{
                                            root: styles.tab,
                                            selected: styles.selected,
                                        }}
                                    />
                                ))}
                            </Tabs>
                            <ProductComplementItemForm
                                disabledClose={values.length === 1}
                                key={item.id}
                                error={errors.find((itemError) => itemError.id === item.id)?.error}
                                disabledEditProduct={disabledEditProduct}
                                complementProductItem={item}
                                onChange={onChangeComplementProductHandle}
                                onRemove={onDeleteComplementProductHandle}
                            />
                        </div>
                        <Separator />
                    </Fragment>
                ))}
                {!disabledEditProduct && (
                    <Button variant="text" onClick={onAddComplementProductHandle} startIcon={<AddCircle />} style={{ width: "150px" }}>
                        Novo Produto
                    </Button>
                )}
            </div>
            <ButtonsForm onCancel={onCancel} color='primary' width="100px" />
        </form>
    );
};

export default ProductComplementListForm;
