import { Autocomplete } from "@mui/material"
import Sidesheet from "components/sidesheet/Sidesheet"
import { IAccessUser } from "modules/catalog/presentation/componentes/productActions/accessProductForm/AccessProductForm";
import { ContainerLabel } from "modules/catalog/presentation/componentes/ui/ContainerLabel"
import { InputLabel } from "modules/catalog/presentation/componentes/ui/InputLabel"
import { IEventsAllowedUsersResponse } from "modules/events/domain/dto/IEventsResponse";
import { useCallback, useEffect, useState } from "react";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import style from "./SidesheetAddEvent.module.scss";
export interface IColaborador {
  id: string;
  name: string;
}


interface ISidesheetAddEvent {
  openAddEvent: string | null
  setOpenAddEvent: React.Dispatch<React.SetStateAction<string | null>>
  allowedUsers?: IEventsAllowedUsersResponse[]
  accessUsers?: IAccessUser[]
  handleSubmit: (colaboradores: IColaborador[]) => void
  handleDelete: (id: string) => void
}

const SidesheetAddEvent = ({ openAddEvent, setOpenAddEvent, allowedUsers, accessUsers, handleSubmit, handleDelete }: ISidesheetAddEvent) => {
  const [colaboradores, setColaboradores] = useState<IColaborador[]>([])
  const [error, setError] = useState<{ colaborador?: string } | undefined>(undefined)

  const validate = useCallback(() => {
    if (colaboradores.length === 0) {
      setError({ colaborador: "Adicione um colaborador" })
      return false
    }

    setError(undefined);
    return true
  }, [colaboradores])


  const handleAddColaborador = () => {
    if (validate()) {
      handleSubmit(colaboradores)
      setColaboradores([])
    }
  }

    return (
      <Sidesheet
        open={openAddEvent}
        onClose={() => {
          setOpenAddEvent(null)
          setColaboradores([])
        }}
        title={<h2><b>Colaboradores</b> com acesso ao evento </h2>}
        content={<div>
          <p>Estes são os promoters com acesso apenas a esta lista. Qualquer alteração feita aqui, irá sobrepor as alterações feitas para os promoters de todas as listas.</p>
          <ContainerLabel>
            <Autocomplete
              fullWidth
              renderInput={(params) =>
                <InputLabel
                  {...params}
                  error={!!error?.colaborador} helperText={error?.colaborador}
                  label={"Adicionar colaboradores:"}
                  required
                  placeholder={colaboradores ? 'Selecione o colaborador' : ''} />
              }
              options={accessUsers ?? []}
              getOptionLabel={(option) => option.name}
              multiple
              value={colaboradores}
              onChange={(event, newValue) => setColaboradores(newValue)}
              size='small'
            />
          </ContainerLabel>
          <div style={{ marginTop: '20px' }}>
            {allowedUsers?.map(item => {
              return (
                <div className={style.colaboradorAdd}>
                  <p>{item.name}</p>
                  <HighlightOffIcon onClick={() => handleDelete(item.id)} />
                </div>
              )
            })}
          </div>
        </div>}

        currentStep={1}
        totalSteps={1}
        continueButton={'Concluir'}
        handleContinueButton={handleAddColaborador}
        notTotalHeight
      />
    )
  }

  export default SidesheetAddEvent