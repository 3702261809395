import { ISort } from "interfaces/ISort";
import { IConsultDocumentsApiService } from "../../domain/interfaces/IPermutationApiService";
import ParseFilterToRequestUseCase from "./ParseFilterToRequestUseCase";
import { IConsultDocumentsfilter } from "../../presentation/components/consultDocumentsFilter/interfaces/ConsultDocumentsFilter";
import { IGetConsultDocumentsResponse } from "../../domain/dto/IGetConsultDocumentsResponse";


const GetConsultDocumentsUseCase = async (service: IConsultDocumentsApiService, localId: string, sort?: ISort, pagination?: { page?: number, pageSize?: number }, filter?: IConsultDocumentsfilter): Promise<IGetConsultDocumentsResponse> => {
   
    const request =  ParseFilterToRequestUseCase(pagination, filter)
    const response = await service.getConsultDocuments(localId, request, sort)
    return (response)
}
export default GetConsultDocumentsUseCase