import Sidesheet from "components/sidesheet/Sidesheet"
import styles from './SidesheetSharedProducts.module.scss'
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { IProductItem } from "../../categoryAndProducts/productList/IProductItem";

interface Props {
    openSidesheet: boolean
    setOpenSidesheet: React.Dispatch<React.SetStateAction<boolean>>;
    products: IProductItem[]
    onChangeDeleteProductsShared: (shared: boolean) => void
}

const SidesheetSharedProducts = ({ 
    openSidesheet, 
    setOpenSidesheet,
    products,
    onChangeDeleteProductsShared,
 }: Props) => {
    const handleChange = (shared : boolean) => {
 
        if(openSidesheet){
            onChangeDeleteProductsShared(shared)
        }
        setOpenSidesheet(false)
    }
    return (
        <Sidesheet
            open={openSidesheet}
            onClose={() => {
                setOpenSidesheet(false);
            }}
            title={
                <h1>
                   Excluír<b> produtos em massa</b>
                </h1>
            }
            content={
                <div id={styles.SidesheetSharedProducts}>
                    <SidesheetFeedback text={'Os produtos listados serão excluídos em todos os locais compartilhados.'} />
                    <p className={styles.title}>Produtos compartilhados</p>
                    {products?.map(item => {
                        return (
                            <div className={styles.item}>
                                {item.name}
                            </div>
                        )
                    })}
                </div>
            }
            cancelButton
            continueButton={"Excluir"}
            handleContinueButton={() => handleChange(true)}
            handleCustomizedCancelButton={() => setOpenSidesheet(false)}
            customizedCancelButton={'Cancelar'}
            currentStep={1}
            totalSteps={1}

        />
    )
}

export default SidesheetSharedProducts