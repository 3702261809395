import { IEventsApi } from "../../interfaces/IEventsService";

const DeleteEventsAllowedUsersUseCase = async (
  eventsService: IEventsApi,
  eventId: string,
  allowedUserId: string,
  localId: string
): Promise<void> => {
  return await eventsService.deleteEventsAllowedUsers(
    eventId,
    allowedUserId,
    localId
  );
};

export default DeleteEventsAllowedUsersUseCase;
