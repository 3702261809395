import React, { FC, useCallback, useEffect, useState } from 'react'
import { useError } from '../../contexts/error/ErrorContext'
import { dashboardContainerEnum } from '../../interfaces/DashboardContainerEnum'
import { IGetDataParams } from '../../interfaces/IGetDataParams'
import RankingList from '../rakingList/RankingList'
import { groupByEnum } from '../rakingProducts/IRankinProduct'
import styles from './CategoryRanking.module.scss'
import './CategoryRanking.css'
import { CategoryRankingValue } from './CategoryRankingValue'

export interface ICategoryRankingProps {
    //propertys
    subCategoryId?: string;
    group?: boolean;
    getData: ({ page, sortField, sortOrientation, groupBy }: IGetDataParams, subCategoryId?: string) => Promise<CategoryRankingValue>
    getSubItems: (params: IGetDataParams, subCategoryId?: string | undefined) => Promise<CategoryRankingValue>
    nested?: boolean;
}

const CategoryRanking: FC<ICategoryRankingProps> = ({ getData, getSubItems, subCategoryId, group = true, nested = false }) => {
    const [categoryData, setCategoryData] = useState<CategoryRankingValue>()
    const [onLoading, setOnLoading] = useState(false);
    const { setOnError, clearContainerError } = useError();
    const [currentPage, setCurrentPage] = useState(0);

    const fetchData = useCallback(async (page?: number) => {
        clearContainerError(dashboardContainerEnum.RANKING_SUBCATEGORY);
        try {
            !page && setOnLoading(true);

            const response = await getData({ page, sortOrientation: "desc", groupBy: groupByEnum.None }, subCategoryId);

            if (page) {
                setCategoryData(prev => ({ ...prev, records: [...prev?.records ?? [], ...response.records], totalRecords: response.totalRecords }));
            } else {
                setCategoryData(prev => ({ ...prev, records: [...response.records], totalRecords: response.totalRecords }));
            }

            if (!page && !response.records.length) {
                setOnError({
                    type: 'warning',
                    containerId: dashboardContainerEnum.RANKING_SUBCATEGORY
                })
            }
        } catch (error: any) {
            setOnError({
                type: 'error',
                containerId: dashboardContainerEnum.RANKING_SUBCATEGORY
            })
        } finally {
            setOnLoading(false);
        }
    }, [clearContainerError, getData, setOnError, subCategoryId]);

    useEffect(() => {
        fetchData(0);
    }, [fetchData]);

    const getMore = useCallback(() => {
        setCurrentPage(currentPage + 1);
        fetchData(currentPage + 1);
    }, [currentPage, fetchData]);

    return (
        <div id={styles.CategoryRanking} >
            <RankingList
                list={categoryData?.records}
                count={categoryData?.totalRecords}
                getMore={getMore}
                isLoading={onLoading}
                colunms={{
                    name: { title: "Categoria" },
                    total: { title: "Total", formatValue: (value) => formatCurrency(value) },
                }}
                nested={nested}
            />
        </div>
    )
}
export default CategoryRanking


const formatCurrency = (value: number) => new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
}).format(value)