import LandingPage from "components/freePage/LandingPage";
import Layout from "components/layout/presentation/Layout";
import { IntegrationTypeEnum } from "modules/config/integrationsConfig/presentation/interfaces/IntegrationTypeEnum";
import { useState, useEffect } from "react";
import ConfigReceitaFederal from "../configReceitaFederal/ConfigReceitaFederal";
import styles from "./ReceitaFederalDetail.module.scss";

export const ReceitaFederalDetail = () => {
  const [open, setOpen] = useState<IntegrationTypeEnum | null>(null);
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (!open) {
      setStep(1);
    }
  }, [open]);

  return (
    <Layout>
      <div id={styles.ReceitaFederalDetail}>
        <LandingPage
          title="Receita Federal"
          subtitle=""
          primaryButtonText="Contratar"
          secondaryButtonText="Quero contratar"
          description="Essa nova funcionalidade simplifica e facilita o cadastro de novos clientes no nosso sistema através de verificação do CPF."
          aboutTitle={<>O que está incluso nessa <span className='purpleText'>integração:</span></>}
          about=""
          logo="/assets/icon/receita-federal-logo.svg"
          includedItems={[
            {
              title: "Baixo custo",
              description: "Cada consulta terá o custo de R$ 0,10. Cobrança única por CPF.",
              isHighlight: true,
              img: "/assets/icon/money-icon.svg",
              className: styles.smallIcon,
            },
            {
              title: "Dados",
              description:
                "Com o CPf é possível informar o nome completo, data de nascimento, nome da mãe e sexo.",
              img: "/assets/icon/info-icon.svg",
            },
            {
              title: "Confiabilidade",
              description:
                "Dados validados pela Receita Federal.",
              img: "/assets/icon/hands-icon.svg",
            },
            {
              title: "POS",
              description:
                "A funcionalidade atende a todos os tipos de operação, quando a identificação de cliente estiver habilitada.",
              img: "/assets/icon/pos-icon.svg",
              className: styles.smallIcon,
            },
            {
              title: "Evento",
              description:
                "Agilidade de cadastro de clientes na lista de convidados",
              img: "/assets/icon/ticket-icon.svg",
            },
            {
              title: "Gestão",
              description:
                "Otimização de gestão de clientes através dos dados obtidos",
              img: "/assets/icon/profile-settings-icon.svg",
            },
          ]}
          setOpen={() => setOpen(IntegrationTypeEnum.RECEITA_FEDERAL)}
        />
      </div>
      {open && open === IntegrationTypeEnum.RECEITA_FEDERAL && (
        <ConfigReceitaFederal
          open={open}
          setOpen={setOpen}
          step={step}
          setStep={setStep}
        />
      )}
    </Layout>
  );
};
