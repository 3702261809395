import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ConfigCrmEmail from "./configCrmEmail/ConfigCrmEmail";
import ConfigCrmWhatsapp from "./configCrmWhatsapp/ConfigCrmWhatsapp";
import ConfigMeepTickets from "./configMeepTickets/ConfigMeepTickets";
import ConfigTrack from "./configTrack/ConfigTrack";
import styles from "./CrmConfigPage.module.scss";
import { Divider } from "@material-ui/core";
import ContentBox from "../components/_configCampaign/contentBox/ContentBox";
import { IntegrationTypeEnum } from "../interfaces/IntegrationTypeEnum";
import ConfigSankhya from "./configSankhya/ConfigSankhya";
import ConfigOmie from "./configOmie/ConfigOmie";
import ConfigIFood from "./configIFood/ConfigIFood";
import ConfigFacebook from "./configFacebook/ConfigFacebook";
import ConfigMobi from "./configMobi/ConfigMobi";
import { EmailDetails } from "modules/addons/presentation/components/emailDetails/EmailDetails";
import ConfigCrmPush from "./configCrmPush/ConfigCrmPush";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import ConfigIFoodMarket from "./configIFoodMarket/ConfigIFoodMarket";
import ConfigReceitaFederal from "./configReceitaFederal/ConfigReceitaFederal";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import ConfigPagSeguro from "./configPagSeguro/ConfigPagSeguro";
import ConfigGetnet from "./configGetnet/ConfigGetnet";
import ConfigMeepHub from "./configMeepHub/ConfigMeepHub";
import ConfigCieloSmart from "./configCieloSmart/ConfigCieloSmart";
import ConfigCielo from "./configCielo/ConfigCielo";
import { useLocation } from "react-router-dom";
import ConfigStone from "./configStone/ConfigStone";

export interface ICrmConfigPageProps {
  //propertys
}

const CrmConfigPage: FC<ICrmConfigPageProps> = () => {
  const [open, setOpen] = useState<IntegrationTypeEnum | null>(null);
  const [step, setStep] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState(["Todas"]);
  const [emailOpened, setEmailOpened] = useState(false);

  const [existPayment, setExistPayment] = useState(false);
  const [creditCard, setCreditCard] = useState(false);

  const hubBox = useRef<HTMLDivElement>(null);

  const { getSubscription } = usePlans();
  const { currentLocal } = useLocal();
  const location = useLocation<{ hub: boolean }>();

  useEffect(() => {
    getSubscription();
  }, [getSubscription]);

  useEffect(() => {
    if (location.state?.hub) {
      setTimeout(() => {
        if (hubBox.current) {
          hubBox.current.scrollIntoView({ behavior: "smooth" });
          setOpen(IntegrationTypeEnum.MEEP_HUB);
        }
      }, 100);
    }
  }, [location]);

  const filters: string[] = useMemo(() => currentLocal?.systemIdentifier === 0
    ? [
      "Todas",
      "Comunicação",
      "Ingressos",
      "Pesquisa",
      "Delivery",
      "ERP",
      "Fiscal",
      "Serviços",
    ] : [
      "Todas",
      "Comunicação",
      "POS",
      "Pesquisa",
      "Delivery",
      "Fiscal",
      "Serviços",
    ],
  [currentLocal?.systemIdentifier]);

  useEffect(() => {
    if (!open) {
      setCreditCard(false);
    }
  }, [open]);

  const handleSelectedFilter = (filter: string) => {
    if (filter === "Todas") {
      if (selectedFilter.length === 1 && selectedFilter.includes(filter)) {
        return;
      }
      if (!selectedFilter.includes(filter)) {
        return setSelectedFilter([filter]);
      }
    }

    const removeAllFilter = selectedFilter.filter((item) => item !== "Todas");

    if (removeAllFilter.includes(filter)) {
      if (removeAllFilter.length === 1) {
        return setSelectedFilter(["Todas"]);
      }
      const removeFilter = removeAllFilter.filter((item) => item !== filter);
      return setSelectedFilter(removeFilter);
    }
    setSelectedFilter([...removeAllFilter, filter]);
  };

  useEffect(() => {
    if (open === null) {
      setStep(1);
    }
  }, [open]);

  const showSection = useCallback((currentFilter: string) => {
    if (currentLocal?.systemIdentifier === 0 && currentFilter === "POS") return false;
    if (currentLocal?.systemIdentifier === 1 && (currentFilter === "Ingressos" || currentFilter === "ERP")) return false;
    if (filters.includes(currentFilter) && (selectedFilter.includes("Todas") || selectedFilter.includes(currentFilter))) return true;
    return false;
  }, [currentLocal?.systemIdentifier, filters, selectedFilter]);

  return (
    <div id={styles.CrmConfigPage}>
      <div className={styles.container}>
        <div className={styles.bannerConfig}>
          <div>
              <h2><b>Integre com sistemas estratégicos</b></h2>
              <span>Conecte com sistemas para tornar a sua gestão ainda mais completa</span>
          </div>
          <img src="/assets/icon/integrations-banner.svg" alt="" />
        </div>
        <div className={styles.containerTitle}>
          <h2>Integrações</h2>
          <p>
            Integre com os principais parceiros e alavanque seu estabelecimento
          </p>
        </div>
        <div className={styles.containerFilters}>
          <p>Selecione o tipo da integração</p>
          <div className={styles.filters}>
            {filters.map((filter) => (
              <button
                key={filter}
                className={
                  selectedFilter.includes(filter) ? styles.selectedFilter : ""
                }
                onClick={() => handleSelectedFilter(filter)}
              >
                {filter}
              </button>
            ))}
          </div>
        </div>
        {showSection("Comunicação") && (
          <>
            <div className={styles.containerTitle}>
              <h4>Comunicação</h4>
              <Divider />
            </div>
            <div className={styles.containerCards}>
              <ConfigCrmWhatsapp />
              <ConfigCrmEmail
                open={open}
                setOpen={(val) => {setOpen(val); setEmailOpened(true)}}
                step={step}
                setStep={setStep}
                showConnect={false}
              >
                <EmailDetails open={emailOpened} onClose={() => setEmailOpened(false)} />
              </ConfigCrmEmail>
              {currentLocal?.systemIdentifier !== 1 && (
                <ConfigCrmPush
                  open={open}
                  setOpen={setOpen}
                  step={step}
                  setStep={setStep}
                  showConnect={false}
                />
              )}
              {/* <ContentBox
              status="unavailable"
              type={IntegrationTypeEnum.SMS}
              text="Ferramenta de envio de SMS para seus clientes, diretamente do CRM Meep"
              setOpen={setOpen}
            /> */}
              <ConfigFacebook
                open={open}
                setOpen={setOpen}
                step={step}
                setStep={setStep}
              />
            </div>
          </>
        )}
        {showSection("Ingressos") && (
          <>
            <div className={styles.containerTitle}>
              <h4>Ingressos</h4>
              <Divider />
            </div>
            <div className={styles.containerCards}>
              <ConfigMeepTickets
                open={open}
                setOpen={setOpen}
                step={step}
                setStep={setStep}
              />
            </div>
          </>
        )}
        {showSection("POS") && (
          <>
            <div className={styles.containerTitle}>
              <h4>POS</h4>
              <Divider />
            </div>
            <div className={styles.containerCards}>
              <ConfigCieloSmart
                open={open}
                setOpen={setOpen}
              />
              <ConfigCielo
                open={open}
                setOpen={setOpen}
              />
              <ConfigPagSeguro
                open={open}
                setOpen={setOpen}
              />
              <ConfigGetnet
                open={open}
                setOpen={setOpen}
              />
              <ConfigStone
                open={open}
                setOpen={setOpen}
              />
            </div>
          </>
        )}
       {(selectedFilter.includes("Todas") ||
          selectedFilter.includes("Pesquisa")) && (
            <>
              <div className={styles.containerTitle}>
                <h4>Pesquisa</h4>
                <Divider />
              </div>
              <div className={styles.containerCards}>
                <ConfigTrack
                  open={open}
                  setOpen={setOpen}
                  step={step}
                  setStep={setStep}
                />
                <ConfigReceitaFederal
                  open={open}
                  setOpen={setOpen}
                  step={step}
                  setStep={setStep}
                />
              </div>
            </>
          )}
        {showSection("Delivery") && (
          <>
            <div className={styles.containerTitle}>
              <h4>Delivery</h4>
              <Divider />
            </div>
            <div className={styles.containerCards}>
              <ConfigIFood
                open={open}
                setOpen={setOpen}
                step={step}
                setStep={setStep}
              />
              {currentLocal?.systemIdentifier !== 1 && (
                <ConfigIFoodMarket
                  open={open}
                  setOpen={setOpen}
                  step={step}
                  setStep={setStep}
                />
              )}
            </div>
          </>
        )}
        {showSection("ERP") && (
          <>
            <div className={styles.containerTitle}>
              <h4>ERP</h4>
              <Divider />
            </div>
            <div className={styles.containerCards}>
              <ConfigOmie
                open={open}
                setOpen={setOpen}
                step={step}
                setStep={setStep}
              />
              <ContentBox
                status="unavailable"
                type={IntegrationTypeEnum.EVEREST}
                showConnect={false}
                text="Integração para gestão de negócios e ERP em diversos serviços"
                setOpen={setOpen}
                getMoreRef="/private/everest/detail"
                soon
              />
              <ConfigSankhya
                open={open}
                setOpen={setOpen}
                step={step}
                setStep={setStep}
                existPayment={existPayment}
                setExistPayment={setExistPayment}
                creditCard={creditCard}
                setCreditCard={setCreditCard}
              />
            </div>
          </>
        )}
        {showSection("Fiscal") && (
          <>
            <div className={styles.containerTitle}>
              <h4>Fiscal</h4>
              <Divider />
            </div>
            <div className={styles.containerCards}>
              <ConfigMobi
                open={open}
                setOpen={setOpen}
                step={step}
                setStep={setStep}
              />
            </div>
          </>
        )}
        {showSection("Serviços") && (
          <>
            <div className={styles.containerTitle}>
              <h4>Serviços</h4>
              <Divider />
            </div>
            <div ref={hubBox} className={styles.containerCards}>
              <ConfigMeepHub
                open={open}
                setOpen={setOpen}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CrmConfigPage;
