import GetAccessUsersUseCase from 'modules/catalog/application/useCases/GetAccessUsersUseCase';
import LimitProductAccessUseCase from 'modules/catalog/application/useCases/LimitProductAccessUseCase';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import React, { FC, useCallback, useState } from 'react'
import { IAccessProductValues } from '../componentes/productActions/accessProductForm/AccessProductForm';
import { IProduct } from '../fragments/catalogFragment/CatalogFragment';
import { ICatalogService } from 'modules/catalog/domain/interface/ICatalogService';
import CatalogService from 'services/api/catalog/CatalogService';
import GetLimitProductAccessUseCase from 'modules/catalog/application/useCases/GetLimitProductAccessUseCase';

const catalogService = CatalogService();
const UseProductAccess = () => {
    const { currentLocal } = useLocal();
    const [productToEditAccess, setProductToEditAccess] = useState<IProduct>();



    const getAccessUsers = useCallback(async () => {
        if (currentLocal) {
            const response = await GetAccessUsersUseCase(catalogService, currentLocal.id);
            return (response);
        } else {
            throw (new Error("Falha ao obter lista de usuários"));
        }
    }, [currentLocal]);

    const getDefaultAccessUsers = useCallback(async (product: IProduct) => {
        if (currentLocal) {
            const response = await GetLimitProductAccessUseCase(catalogService, product.id);
            return (response);
        } else {
            throw (new Error("Falha ao obter lista de usuários"));
        }
    }, [currentLocal]);

    const onSubmitAccessProductHandle = useCallback(async (product: IProduct, values: IAccessProductValues) => {
        if (currentLocal) {
            const response = await LimitProductAccessUseCase(catalogService, product.id, values, product.AllSharedProducts);
            return (response);
        } else {
            throw (new Error("Falha ao salvar permissões de acesso"));
        }
    }, [currentLocal]);


    //LIMITAR ACESSO AO PRODUTO
    return ({
        getAccessUsers,
        onSubmitAccessProductHandle,
        getDefaultAccessUsers,
        productToEditAccess,
        setProductToEditAccess
    })
}

export default UseProductAccess