import { Icon } from "@mui/material";
import style from "components/sidesheet/Sidesheet.module.scss";
import { useHistory } from "react-router-dom";
import styles from './ConfigContentConnectedReceitaFederal.module.scss'
import { NorthEast } from "@mui/icons-material";
import { IGetReceitaFederalResponse } from "modules/config/integrationsConfig/domain/dto/IGetReceitaFederalResponse";
import Utils from "services/utils/Utils";

export interface IConfigContentConnectedReceitaFederalProps {
  goReportConsultDocuments: () => void
  values: IGetReceitaFederalResponse | undefined
}

const ConfigContentConnectedReceitaFederal = (props: IConfigContentConnectedReceitaFederalProps) => {


  return (
    <div className={style.configContainer}>
      <p>Aqui você pode conferir a data de contratação e a quantidade de consultas realizadas.</p>
      <p>Data da contratação: <b>{Utils.toDateAndTime(props.values?.acceptedAt ?? '')}</b></p>
      <p>Total de clientes já consultados:  <b>{props.values?.totalDocuments}</b></p>
      <p className={styles.reportConsult} onClick={props.goReportConsultDocuments}>Relatórios de consultas <NorthEast/></p>
    </div>
  );
};

export default ConfigContentConnectedReceitaFederal;
