import React, { FC } from 'react';
import styles from './ui.module.scss';
import { Switch, SwitchProps } from '@material-ui/core';

//switch
interface SwitchLabelProps {
    label: string;
}
export const SwitchLabel: FC<SwitchLabelProps & SwitchProps> = (props) => {
    return <div aria-label={props.label} className={styles.SwitchLabel}><Switch {...props} onChange={props.onChange} checked={props.checked}/> <label>{props.label}</label> </div>;
};
