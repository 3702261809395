import { useCallback, useState } from "react";
import { ISort } from "interfaces/ISort";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { IConsultDocumentsfilter } from "../components/consultDocumentsFilter/interfaces/ConsultDocumentsFilter";
import { IConsultDocumentsList } from "../interfaces/IConsultDocumentsList";
import GetConsultDocumentsUseCase from "../../application/useCases/GetConsultDocumentsUseCase";
import ConsultDocumentsApi from "services/api/consultDocuments/ConsultDocumentsApi";
import PostConsultDocumentsExportUseCase from "../../application/useCases/PostConsultDocumentsExportUseCase";

export interface IParamsConsultDocumentsList {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  sort?: ISort;
  filter?: IConsultDocumentsfilter;
  format?: string;
}

const UseConsultDocumentsListExport = () => {

  const [isLoadingExport, setIsLoadingExport] = useState<boolean>();
  const [consultDocumentsListExport, setConsultDocumentsListExport] = useState<IConsultDocumentsList>()
  const { currentLocal } = useLocal();

  const postConsultDocumentsListExport = useCallback(
    async (params?: IParamsConsultDocumentsList) => {
      try {
        const serviceConsultDocuments = ConsultDocumentsApi();
        setIsLoadingExport(true);
        if (currentLocal) {
          const response = await PostConsultDocumentsExportUseCase(serviceConsultDocuments,
            currentLocal.id,
            params?.sort,
            params?.pagination ?? { page: 0, pageSize: 20 },
            params?.filter,
            params?.format
          );

          setConsultDocumentsListExport(response);

          return 'ok'
        }
      } finally {
        setIsLoadingExport(false);
      }
    },
    [currentLocal]
  );


  return {
    consultDocumentsListExport,
    isLoadingExport,
    postConsultDocumentsListExport,
    setConsultDocumentsListExport,
  };
};

export default UseConsultDocumentsListExport;
