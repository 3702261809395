import { IEventsApi } from "../../interfaces/IEventsService";

const PostLocalAllowedUsersUseCase = async (
  service: IEventsApi,
  localId: string,
  allowedUserIds: string[]
): Promise<void> => {
  const response = await service.postLocalAllowedUsers(localId, allowedUserIds);
  return response;
};
export default PostLocalAllowedUsersUseCase;
