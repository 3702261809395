import { useUi } from "contexts/userInterface/UserInterfaceContext";
import DeleteContractReceitaFederalUseCase from "modules/config/integrationsConfig/application/useCases/DeleteContractReceitaFederalUseCase";
import GetReceitaFederalUseCase from "modules/config/integrationsConfig/application/useCases/GetReceitaFederalUseCase";
import PostReceitaFederalConfigUseCase from "modules/config/integrationsConfig/application/useCases/PostReceitaFederalConfigUseCase";
import { IGetReceitaFederalResponse } from "modules/config/integrationsConfig/domain/dto/IGetReceitaFederalResponse";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import IntegrationApi from "services/api/config/integration/IntegrationApi";

const service = IntegrationApi();

export const UseConfigReceitaFederal = () => {

  const [isLoading, setIsLoading] = useState(false);
  const { currentLocal } = useLocal();
  const { toast } = useUi();
  const [values, setValues] = useState<IGetReceitaFederalResponse>()

  const getReceitaFederalStatus = useCallback(async () => {
    if (currentLocal) {
      try {
        setIsLoading(true);
        const response = await GetReceitaFederalUseCase(service, currentLocal.id)
        setValues(response)
      } catch {
        toast("Ocorreu um erro. Tente novamente", "error");
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentLocal, toast]);

  useEffect(() => {
    getReceitaFederalStatus()
  }, [getReceitaFederalStatus])

  const postReceitaFederal = useCallback(async () => {
    if (currentLocal) {
      try {
        setIsLoading(true);
        await PostReceitaFederalConfigUseCase(service, currentLocal.id);
        await getReceitaFederalStatus()
        return "ok";
      } catch {
        toast("Ocorreu um erro. Tente novamente", "error");
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentLocal, getReceitaFederalStatus, toast]);

  const deleteContratoReceitaFederal = useCallback(
    async () => {
      if(currentLocal) {

        try {
          setIsLoading(true);
          await DeleteContractReceitaFederalUseCase(service, currentLocal.id);
          await getReceitaFederalStatus();
          return "ok";
        } catch {
          toast(
            "Não foi possível excluir o método de pagamento. Tente novamente",
            "error"
          );
        } finally {
          setIsLoading(false);
        }
      }
    },
    [currentLocal, getReceitaFederalStatus, toast]
  );




  return {
    isLoading,
    getReceitaFederalStatus,
    postReceitaFederal,
    values,
    deleteContratoReceitaFederal
  };
};
