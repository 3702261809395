import { ImportExport } from "@mui/icons-material";
import React, { FC, useCallback, useMemo } from "react";
import styles from "./ConsultDocuments.module.scss";
import { IConsultDocumentsColumnsConfig } from "../../../consultDocumentsList/interfaces/IColumnsConfig";
import { IParamsConsultDocumentsList } from "../../../../hooks/UseConsultDocumentsList";

interface DiscountCouponHeaderProps {
  columns: IConsultDocumentsColumnsConfig[],
  params: IParamsConsultDocumentsList;
  setParams: React.Dispatch<React.SetStateAction<IParamsConsultDocumentsList>>
}


const ConsultDocumentsHeader: FC<DiscountCouponHeaderProps> = ({ columns, setParams }) => {


  const onClickOrderColumn = useCallback((orderBy: string) => {
    setParams(prev => ({ ...prev, sort: { type: orderBy, orientation: prev?.sort?.orientation === 'asc' ? 'desc' : 'asc' }, pagination: { page: 0, pageSize: prev.pagination?.pageSize } }))

  }, [setParams]);


  const header = useMemo(() => columns.map((column, index) =>
    !column.hide && <div
      key={index}
      className={styles.row}
      style={column.style}
    >
      <span className={styles.order}>{column.title} { column.property === 'date'  && <ImportExport  onClick={() => onClickOrderColumn(column.property)} />} </span>
    </div>
  ), [columns, onClickOrderColumn])

  return (
    <div id={styles.ConsultDocumentsHeaderContainer}>
      <div className={styles.container}>      
        {header}  
      </div>
    </div>
  );
};

export default ConsultDocumentsHeader;
