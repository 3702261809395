import React, { FC, useCallback, useEffect, useState } from 'react'
import CatalogService from 'services/api/catalog/CatalogService';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import GetMainCategoriesAndInvoicSplitFiscalUseCase from 'modules/catalog/application/useCases/_GetMainCategoriesAndInvoicSplitFiscalUseCase';
import { useLocation } from 'react-router-dom';
import GetCategoryUseCase from 'modules/catalog/application/useCases/GetCategoryUseCase';
import CatalogProductCategoryForm, { ICatalogProductCategoryFormValue, ISelectCategory, ISelectLocal } from '../../componentes/_catalogProductCategoryForm/CategoryForm';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import AddProductCategoryUseCase from 'modules/catalog/application/useCases/AddProductCategoryUseCase';
import EditProductCategoryUseCase from 'modules/catalog/application/useCases/EditProductCategoryUseCase';
import { Skeleton } from '@mui/material';
import Sidesheet from 'components/sidesheet/Sidesheet';
export interface ICatalogCategoryFragmentProps {
    //propertys
    onClose: () => void;
    reloadCategories: () => void
}

const catalogService = CatalogService()

const CatalogCategoryFragment: FC<ICatalogCategoryFragmentProps> = ({ onClose, reloadCategories }) => {
    const { currentLocal } = useLocal();
    const [mainsCategories, setMainsCategories] = useState<ISelectCategory[]>();
    const [invoiceSplitLocals, setInvoiceSplitLocals] = useState<ISelectLocal[]>();
    const [openCategoryForm, setOpenCategoryForm] = useState(false)
    const [categoryId, setCategoryId] = useState<string>();
    const { search } = useLocation();
    const { showLoading, hideLoading } = useUi();
    const params = React.useMemo(() => new URLSearchParams(search), [search]);
    const [isLoading, setIsLoading] = useState(false);
    const [defaultValues, setDefaultValues] = useState<ICatalogProductCategoryFormValue>()


    useEffect(() => {
        const _category = params.get("categoryId");
        const _action = params.get("action");
        setOpenCategoryForm(_action === "addCategory" || _action === "editCategory");
        if (_action === "addCategory") {
            setDefaultValues(undefined);
        }
        if (_category) {
            setCategoryId(_category)
        }

    }, [params])


    useEffect(() => {
        if (!openCategoryForm) {
            setCategoryId(undefined);
            setDefaultValues(undefined);
        }
    }, [openCategoryForm])




    const GetMainCategoriesAndInvoiceSplitLocals = useCallback(async () => {
        if (currentLocal) {
            const response = await GetMainCategoriesAndInvoicSplitFiscalUseCase(catalogService, currentLocal.id)
            setInvoiceSplitLocals(response.invoiceSplitLocals ?? [])
            setMainsCategories(response.mainCategories ?? [])
        }
    }, [currentLocal]);

    const GetDefaultCategory = useCallback(async (categoryId: string) => {
        try {
            if (currentLocal && mainsCategories && invoiceSplitLocals) {
                setIsLoading(true);
                const response = await GetCategoryUseCase(catalogService, currentLocal.id, categoryId, mainsCategories, invoiceSplitLocals)           
                setDefaultValues(response)
            }
        } finally {
            setIsLoading(false);
        }
    }, [currentLocal, invoiceSplitLocals, mainsCategories]);


    useEffect(() => {
        GetMainCategoriesAndInvoiceSplitLocals();
    }, [GetMainCategoriesAndInvoiceSplitLocals])

    useEffect(() => {
        if (categoryId) {
            GetDefaultCategory(categoryId)
        }
    }, [GetDefaultCategory, categoryId])


    const onSubmitHandle = useCallback(async (values: ICatalogProductCategoryFormValue) => {
        try {
            showLoading();
            if (currentLocal) {
                if (categoryId) {
                    await EditProductCategoryUseCase(catalogService, currentLocal.id, categoryId, values)
                } else {
                    await AddProductCategoryUseCase(catalogService, currentLocal.id, values)
                }
                reloadCategories();
                setOpenCategoryForm(false);
                onClose();
            }
        } finally {
            hideLoading();

        }
    }, [categoryId, currentLocal, hideLoading, onClose, reloadCategories, showLoading])


    return (
        <Sidesheet
            open={openCategoryForm}
            onClose={onClose}
            title={<h2>{defaultValues ? 'Editar' : 'Nova'} <b>categoria</b></h2>}
            content={isLoading 
                ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Skeleton variant="text" width={'100%'} height={40} />
                        <Skeleton variant="text" width={'100%'} height={40} />
                        <Skeleton variant="text" width={150} height={40} />
                        <div style={{ display: 'flex', gap: 8, justifyContent: 'flex-end' }}>
                            <Skeleton variant="rectangular" width={120} height={40} style={{ marginTop: 20 }} />
                            <Skeleton variant="rectangular" width={120} height={40} style={{ marginTop: 20 }} />
                        </div>
                    </div>
                ) : (
                    <CatalogProductCategoryForm
                        onSubmit={onSubmitHandle}
                        defaultValues={defaultValues}
                        mainCategories={mainsCategories ?? []}
                        invoiceSplitLocals={invoiceSplitLocals ?? []}
                        onCancel={onClose}
                    />
                )
            }
            currentStep={0}
            totalSteps={0}
            notTotalHeight
        />
    )
}

export default CatalogCategoryFragment;