export enum IntegrationTypeEnum {
  WHATSAPP = 'Whatsapp',
  EMAIL = 'Email',
  MEEP_TICKETS = 'Meep Tickets',
  TRACK = 'Track.co',
  SMS = 'SMS',
  FACEBOOK = 'Facebook',
  IFOOD = 'iFood',
  IFOOD_MARKET = 'iFood Mercado',
  OMIE = 'OMIE',
  EVEREST = 'Everest',
  SANKHYA = 'Sankhya',
  MOBI = 'MOBI',
  PUSH = 'Push no App',
  CIELO_SMART = 'Smart',
  CIELO = 'Modo Integrado',
  PAGSEGURO = 'PagSeguro',
  GETNET = 'Getnet',
  STONE = 'Stone',
  MEEP_HUB = 'Meep Hub',
  RECEITA_FEDERAL = 'Receita Federal',
}
