import React, { useCallback, useState } from 'react'
import { AddCircle, VerticalAlignBottomOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import styles from './ConsultDocumentsPage.module.scss'
import { ConsultDocumentsFilter } from '../components/consultDocumentsFilter/ConsultDocumentsFilter'
import { UseConsultDocumentsPage } from './UseConsultDocumentsPage'
import { ConsultDocumentsList } from './consultDocumentsList/ConsultDocumentsList'
import { moneyMaskNumber } from 'services/utils/Money'
import Sidesheet from 'components/sidesheet/Sidesheet'
import SidesheetFeedback from 'components/sidesheet/sidesheetFeedback/SidesheetFeedback'
import UseConsultDocumentsListExport from '../hooks/UseConsultDocumentsListExport'
import ExportValidation from '../components/exportValidation/ExportValidation'

export enum IExportType {
  PDF = 1,
  EXCEL
}

export const ConsultDocumentsPage = () => {


  const [openDrownExport, setOpenDrownExport] = useState(false)
  const [step, setStep] = useState(1);
  const [exportType, setExportType] = useState<string>()


  const { consultDocumentsList, getConsultDocumentsList, isLoading, onChangeFilterHandle, onChangePaginationHandle, params, setParams } = UseConsultDocumentsPage();
  const { postConsultDocumentsListExport, consultDocumentsListExport, isLoadingExport, setConsultDocumentsListExport } = UseConsultDocumentsListExport();


  const onClose = useCallback(() => {
    setStep(1)
    setOpenDrownExport(false)
  }, [])

  const continueOrSave = useCallback(async () => {
    if (step === 2) {
      onClose();
    } else {
      const res = await postConsultDocumentsListExport({ ...params, format: exportType });
      if (res === 'ok') {
        return setStep(step + 1);
      }
    }
  }, [exportType, postConsultDocumentsListExport, onClose, params, step])

  const changeSelectExportType = useCallback((exportType: string) => {
    setExportType(exportType);
}, []);


  const handleContent = useCallback(() => {

    let message = `Selecione abaixo uma opção para exportar o relatório. O mesmo será enviado por email`;   

    return <ExportValidation text={message}  changeSelectExportType={changeSelectExportType}/>

  }, [changeSelectExportType])

  const closedOrDeleteButton = useCallback(() => {
    if (step === 1 ) {
      return "Exportar"
    } else {
      return "Fechar"
    }
  }, [step])

  return (
    <div id={styles.ConsultDocumentsPage}>
      <div className={styles.title}>
        <p>Relatório de consultas</p>
        <p>Acompanhe quantas consultas foram realizadas e o custo total.</p>
      </div>

      <div className={styles.buttonExportReport}>
        {
          <Button onClick={() => setOpenDrownExport(true)}>
            Exportar
            <VerticalAlignBottomOutlined />
          </Button>
        }
      </div>

      <ConsultDocumentsFilter onSubmit={onChangeFilterHandle} />

      <div className={styles.totalCost}>
        <span>Custo total</span>
        <span>{moneyMaskNumber(consultDocumentsList?.totalCost)}</span>
      </div>

      <ConsultDocumentsList
        consultDocumentsList={consultDocumentsList}
        isLoading={isLoading}
        onChangePaginationHandle={onChangePaginationHandle}
        params={params}
        setParams={setParams}
        pagination={params?.pagination}
      />

      <Sidesheet
        isLoading={isLoadingExport}
        open={openDrownExport}
        onClose={onClose}
        title={
          <h2>
            Exportar
          </h2>
        }
        content={handleContent()}
        currentStep={step}
        totalSteps={2}
        continueButton={closedOrDeleteButton()}
        handleContinueButton={continueOrSave}
        feedback={
          <SidesheetFeedback text="Relatório exportado e enviado por email com sucesso!" success />
        }
      />


    </div>
  )
}
