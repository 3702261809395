import { ILocalUsersResponse } from "modules/events/domain/dto/ILocalUsersResponse";
import { IEventsApi } from "../../interfaces/IEventsService";

const GetLocalUsersUseCase = async (
  service: IEventsApi,
  localId: string,
  eventId?: string
): Promise<ILocalUsersResponse[]> => {
  const response = await service.getLocalUsers(localId, eventId);
  return response;
};
export default GetLocalUsersUseCase;
