import { IProduct } from "models/product/IProduct";

export enum EnumTypeGuestFailed {
  RESOLVED = -2,
  ALERT_BLOCK = -1,
  ALERT,
  BLOCK,
}

export interface IGuest {
  id: string;
  name: string;
  cpf: string,
  phone: number,
  promoterId: string;
  promoterName: string;
  invitedAt: string,
  checkinAt: string;
  listId: string,
  listName: string;
}

export interface IAddress {
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
}

export interface IAttractionsRequest {
  id?: string;
  name: string;
  imageUrl: string;
}

export interface IAttractionsResponse {
  id: string;
  name: string;
  imageUrl: string;
}

export interface IEventsForm {
  id?: string;
  name: string;
  imageUrl: string;
  address?: IAddress;
  attractions: IAttractionsRequest[];
  description?: string;
  latitude?: number | null;
  longitude?: number | null;
  startDateString: string;
  endDateString: string;
  attractionName?: string;
  startSaleDate: string;
  endSaleDate: string;
}

export interface IPeopleList {
  name: string;
  description: string;
  closingDateTime: string;
  eventId: string;
  eventName: string;
  people: IGuest[];
}

export interface IGuestFailedInList {
  cpf: string;
  kind: EnumTypeGuestFailed;
  removed: boolean;
}

export interface IGuestAlreadyInList {
  cpf: string;
  name: string;
  listId: string;
  listName: string;
}

export interface IAddGuestForm {
  cpfList: string[];
  IgnoreAlert: boolean;
}

export interface IOpeningDatesResponse {
  startDate: Date;
  endDate: Date;
}

export interface IResponseAddress {
  address: string | null;
  latitude: string | null;
  longitude: string | null;
}

export interface ILocalResponse {
  id: string;
  name: string;
}

export interface ICashier {
  cashierId: string;
  cashierName: string;
  startDate: string;
  endDate?: string;
}
export interface IEvents {
  notDefinedCheckinNotInvitedCount?: number;
  notDefinedGuestSales?: number;
  totalCount?: number;
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  address: IResponseAddress;
  attractions: IAttractionsResponse[];
  startDate: Date;
  availableForSalesStart: string;
  availableForSalesEnd: string;
  local: ILocalResponse;
  guestCount: number;
  maleGuestCount: number;
  femaleGuestCount: number;
  totalGuestCheckin: number;
  totalCheckinNotInvitedCount: number;
  maleGuestCheckin: number;
  maleCheckinNotInvitedCount: number;
  femaleGuestCheckin: number;
  femaleCheckinNotInvitedCount: number;
  totalGuestSales: number;
  totalSalesNotInvited: number;
  maleGuestSales: number;
  maleNotInvitedSales: number;
  femaleGuestSales: number;
  femaleNotInvitedSales: number;
  openingDates: IOpeningDatesResponse[];
  isActive: boolean;
  cashier?: ICashier;
  cashierId?: string;
  products: IProduct[];
  ioticketEventId?: number;
  originType: OriginType;
}

export enum OriginType {
  Portal = 0,
  POS = 1,
}

export enum EventStatusEnum {
  ALL = 0,
  SCHEDULED = 1,
  HAPPENING = 2,
  CLOSED = 3,
}

export interface IEventsFilter {
  id: EventStatusEnum;
  label: string;
}
