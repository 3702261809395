import { IEventsApi } from "../../interfaces/IEventsService";

const DeleteLocalAllowedUsersUseCase = async (
  eventsService: IEventsApi,
  allowedUserId: string,
  localId: string
): Promise<void> => {
  return await eventsService.deleteLocalAllowedUsers(allowedUserId, localId);
};

export default DeleteLocalAllowedUsersUseCase;
