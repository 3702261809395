import Sidesheet from "components/sidesheet/Sidesheet"
import styles from './SidesheetShared.module.scss'
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";

interface Props {
    openSidesheet: string | null
    setOpenSidesheet: React.Dispatch<React.SetStateAction<string | null>>;
    onOpenDeleteProductHandle: (shared: boolean) => void
    onClickCopyProductHandle: (shared: boolean) => void
    onClickProductAccessHandle: (shared: boolean) => void
    locals?: {
        name: string
    }[]
    onChangeFeatured: (shared: boolean) => void
    onChangeEnabled: (shared: any) => void
    onChangeHide: (shared: boolean) => void
}

const SidesheetShared = ({ 
    openSidesheet, 
    setOpenSidesheet, 
    onOpenDeleteProductHandle,
    onClickProductAccessHandle,
    onClickCopyProductHandle,
    locals,
    onChangeFeatured,
    onChangeHide,
    onChangeEnabled,
 }: Props) => {
    const handleChange = (shared : boolean) => {        
        if(openSidesheet === 'Excluir'){
            onOpenDeleteProductHandle(shared)
        }
        if(openSidesheet === 'Duplicar'){
            onClickCopyProductHandle(shared)
        }
        if(openSidesheet === 'Bloquear'){
            onClickProductAccessHandle(shared)
        }
        if(openSidesheet === 'Favoritar'){
            onChangeFeatured(shared)
        }
        if(openSidesheet === 'Ativar/Desativar'){
            onChangeEnabled(shared)
        }
        if(openSidesheet === 'Habilitar/desabilitar'){
            onChangeHide(shared)
        }
        setOpenSidesheet(null)
    }
    return (
        <Sidesheet
            open={openSidesheet}
            onClose={() => {
                setOpenSidesheet(null);
            }}
            title={
                <h1>
                    {openSidesheet}<b> produto</b>
                </h1>
            }
            content={
                <div id={styles.SidesheetShared}>
                    <SidesheetFeedback text={'Deseja modificar apenas neste local ou em todos compartilhados?'} />
                    {locals?.map(item => {
                        return (
                            <div className={styles.item}>
                                {item.name}
                            </div>
                        )
                    })}
                    <p className={styles.info}><b>Somente neste local</b>: Ao escolher essa opção o produto é desvinculado dos outros portais.</p>
                    <p className={styles.info}><b>Em todos locais compartilhados</b>: As alterações serão compartilhadas com todos os locais.</p>
                </div>
            }
            cancelButton
            continueButton={"Somente neste local"}
            handleContinueButton={() => handleChange(false)}
            handleCustomizedCancelButton={() => handleChange(true)}
            customizedCancelButton={'Em todos locais compartilhados'}
            currentStep={1}
            totalSteps={1}

        />
    )
}

export default SidesheetShared