import React, { useCallback } from 'react'
import styles from './CategoryList.module.scss'
import { ICategoryItem } from './ICategoryItem'
import { IProductItem } from '../productList/IProductItem'
import DnDList, { IItem } from '../../ui/DnDList'
import CategoryItem from './CategoryItem'
import OrderCategoriesUseCase from 'modules/catalog/application/useCases/OrderCategories'
import CatalogService from 'services/api/catalog/CatalogService'
import { useLocal } from 'modules/local/presentation/context/LocalContext'

export interface ICategoryListProps {
    categoryList: ICategoryItem[];
    onClickEditCategory?: (values: ICategoryItem) => void
    onClickEditProduct?: (values: IProductItem) => void
    onClickRemoveProduct?: (values: IProductItem) => Promise<void>
    onClickDeleteProduct?: (values: IProductItem, shared: boolean) => Promise<void>
    onClickAddProduct?: (item: ICategoryItem) => void
    onClickCopyProduct?: (item: IProductItem, shared?: boolean) => Promise<void>
    onChangeEnabledProduct?: (product: IProductItem, value: boolean, shared: boolean) =>  Promise<boolean | void>
    onChangeFeaturedProduct?: (product: IProductItem, value: boolean, shared?: boolean) => void
    getProducts?: (item: ICategoryItem) => Promise<IProductItem[]>
    hideEmptyCategories?: boolean
    onClickRemoveCategory?: (values: ICategoryItem) => Promise<void>
    onClickCheckProducts?: (product: IProductItem, value: boolean) => void
    onClickProductAccess?: (product: IProductItem, shared?: boolean) => void
    disabledHidProduct?: boolean
    onChangeHide?: (product: IProductItem, value: boolean, shared?: boolean) => void
    currentCatalogId?: string
    isDragInDropDisabled?: boolean
}
const CategoryList = ({ categoryList, 
    onClickAddProduct, 
    onClickRemoveProduct, 
    onClickCheckProducts, 
    onClickProductAccess, 
    disabledHidProduct, 
    hideEmptyCategories, 
    onChangeFeaturedProduct, 
    onClickEditCategory, 
    onClickEditProduct, 
    getProducts, 
    onChangeEnabledProduct, 
    onClickRemoveCategory, 
    onClickCopyProduct, 
    onClickDeleteProduct, 
    currentCatalogId, 
    onChangeHide,
    isDragInDropDisabled,
}: ICategoryListProps) => {

    const catalogService = CatalogService();
    const { currentLocal } = useLocal()

    const onChangeOrder = useCallback(
        async (list: IItem[]) => {
            if (currentLocal) {
                const body = {
                    localId: currentLocal.id,
                    categorias: list.map((item, index: number) => ({
                        categoriaId: item.id,
                        ordenacao: index
                    }))
                }
                await OrderCategoriesUseCase(catalogService, body);
            }
        },
        [catalogService, currentLocal],
    )

    return (
        <div id={styles.CategoryList} >
            <DnDList
                isDragInDropDisabled={isDragInDropDisabled ?? false}
                list={categoryList}
                onChangeOrder={onChangeOrder}
                renderItem={(item, index, dragHandleProps) => <CategoryItem
                    key={index}
                    categoryItem={item}
                    isDragInDropDisabled={isDragInDropDisabled ?? false}
                    hideEmptyCategories={hideEmptyCategories}
                    onClickAddProduct={onClickAddProduct}
                    onClickCopyProduct={onClickCopyProduct}
                    onClickRemoveProduct={onClickRemoveProduct}
                    onClickDeleteProduct={onClickDeleteProduct}
                    onClickEdit={onClickEditCategory}
                    onClickEditProduct={onClickEditProduct}
                    getProducts={getProducts}
                    onChangeFeaturedProduct={onChangeFeaturedProduct}
                    onChangeEnabledProduct={onChangeEnabledProduct}
                    onCheckProduct={onClickCheckProducts}
                    onClickProductAccess={onClickProductAccess}
                    disabledHidProduct={disabledHidProduct}
                    onClickDelete={onClickRemoveCategory}
                    onChangeHide={onChangeHide}
                    currentCatalogId={currentCatalogId}
                    dragHandleProps={dragHandleProps}
                />}
            />
        </div>
    )
}
export default CategoryList