import Sidesheet from "components/sidesheet/Sidesheet"
import styles from './SidesheetSharedChangeTax.module.scss'
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { IProductItem } from "../../categoryAndProducts/productList/IProductItem";
import { useState } from "react";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import InputMasked from "components/inputMasked/InputMasked";

interface Props {
    openSidesheet: boolean
    setOpenSidesheet: React.Dispatch<React.SetStateAction<boolean>>;
    products: IProductItem[]
    onChangeChangeTaxShared: (shared: boolean, taxData: {
        cfop: string;
        cest: string;
        ncm: string;
    }) => void
}

const SidesheetSharedChangeTax = ({ 
    openSidesheet, 
    setOpenSidesheet,
    products,
    onChangeChangeTaxShared,
 }: Props) => {

    const [cfop, setCfop] = useState("");
    const [cest, setCest] = useState("");
    const [ncm, setNcm] = useState("");
    const [errors, setErrors] = useState<{
      cfop?: string;
      cest?: string;
      ncm?: string;
    }>();
    const width = window.innerWidth;

    const handleValidate = (shared: boolean) => {
        setErrors(undefined);
        let hasError = false;
        if (!cfop) {
          setErrors((prev) => ({ ...prev, cfop: "Campo obrigatório" }));
          hasError = true;
        } else if (!cfop.match(/[0-9]{1}\.[0-9]{3}/)) {
          setErrors((prev) => ({
            ...prev,
            cfop: "O CFOP deve estar no padrão 9.999",
          }));
          hasError = true;
        }
    
        if (!cest) {
          setErrors((prev) => ({ ...prev, cest: "Campo obrigatório" }));
          hasError = true;
        } else if (!cest.match(/[0-9]{2}\.[0-9]{3}\.[0-9]{2}/)) {
          setErrors((prev) => ({
            ...prev,
            cest: "O CEST deve estar no padrão 99.999.99",
          }));
          hasError = true;
        }
    
        if (!ncm) {
          setErrors((prev) => ({ ...prev, ncm: "Campo obrigatório" }));
          hasError = true;
        } else if (!ncm.match(/[0-9]{4}\.[0-9]{2}\.[0-9]{2}/)) {
          setErrors((prev) => ({
            ...prev,
            ncm: "O NCM deve estar no padrão 9999.99.99",
          }));
          hasError = true;
        }
    
        if (!hasError) onChangeChangeTaxShared(shared, {cest, cfop, ncm});
      };

    
    return (
        <Sidesheet
            open={openSidesheet}
            onClose={() => {
                setOpenSidesheet(false);
            }}
            title={
                <h1>
                   Alterar<b> dados fiscais em massa</b>
                </h1>
            }
            content={
                <div id={styles.SidesheetSharedChangeTax}>
                    <SidesheetFeedback text={'Os produtos listados serão editados em todos os locais compartilhados.'} />
                <p className={styles.title}>Produtos compartilhados</p>
                <div className={styles.listProduct}>
                    {products?.map(item => {
                        return (
                            <div className={styles.item}>
                                {item.name}
                            </div>
                        )
                    })}
                </div>
                <p className={styles.title}>Informações fiscais</p>
                <div className={styles.form}>                
                    <FormItemContainer label={'NCM'}>
                        <InputMasked mask="9999.99.99" error={!!errors?.ncm} helperText={errors?.ncm}  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setNcm(e.target.value)} name="ncm" value={ncm} type={ 600 ? "number" : "tel"} />
                    </FormItemContainer>
                    <FormItemContainer label={'CEST'}>
                        <InputMasked mask="99.999.99" onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setCest(e.target.value)} name="cest" value={cest} type={width > 600 ? "number" : "tel"} />
                    </FormItemContainer>
                    <FormItemContainer label={'CFOP'}>
                        <InputMasked mask="9.999" error={!!errors?.cfop} helperText={errors?.cfop} onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setCfop(e.target.value)} name="cfop" value={cfop} type={width > 600 ? "number" : "tel"} />
                    </FormItemContainer>
                </div>
                </div>
            }
            cancelButton
            continueButton={"Concluir"}
            handleContinueButton={() => handleValidate(true)}
            handleCustomizedCancelButton={() => setOpenSidesheet(false)}
            customizedCancelButton={'Cancelar'}
            currentStep={1}
            totalSteps={1}

        />
    )
}

export default SidesheetSharedChangeTax