import React, { FormEvent, useCallback, useState } from 'react'
import styles from './ConsultDocumentsFilter.module.scss'
import { TextField } from '@material-ui/core'
import Button from 'components/ui/Button/Button'
import { IConsultDocumentsfilter } from './interfaces/ConsultDocumentsFilter';

export interface IConsultDocumentsFilterProps {
  onSubmit: (filter: IConsultDocumentsfilter) => void;
}
export const ConsultDocumentsFilter: React.FC<IConsultDocumentsFilterProps> = ({ onSubmit }) => {

  const [values, setValues] = useState<IConsultDocumentsfilter>();

  const changeHandleName = useCallback((name: string) => {
    setValues((prev) => ({ ...prev, keyword: name }));
  }, []);

  const changeHandleDate = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
  }, []);

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      values && onSubmit(values);
    },
    [onSubmit, values]
  );

  return (
    <div id={styles.ConsultDocumentsfilter}>
      <div id={styles.HouseFlowFilter}>
        <form onSubmit={submitHandle}>
          <div className={styles.containerFilter}>
            <div className={styles.content}>
              <div className={styles.balance}>
                <div className={styles.item}>
                  <label>Início</label>
                  <TextField size='small' type="date" fullWidth variant='outlined' name='startDate'  onChange={changeHandleDate} />
                </div>
                <div className={styles.item}>
                  <label>Fim</label>
                  <TextField type="date" size='small' fullWidth variant='outlined' name='endDate'  onChange={changeHandleDate} />
                </div>
              </div>
              <div className={styles.period}>
                <label>Filtrar por usuário</label>
                <TextField fullWidth inputProps={{ maxLength: 250 }} size='small' placeholder='Insira o nome' variant='outlined' name={'keyboard'} onChange={(ev) => changeHandleName(ev.target.value)} />

              </div>
            </div>
            <div className={styles.footer}>
              <Button variant={'contained'} className={styles.button} fullWidth type='submit' >Buscar</Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
