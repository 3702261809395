import styles from "./FreeContentCard.module.scss";

export interface IFreeContentCardProps {
  img?: string;
  title: string;
  description: string;
  isMobile?: boolean;
  isHighlight?: boolean;
  className?: string;
}

const FreeContentCard = ({
  img,
  title,
  description,
  isMobile,
  isHighlight = false,
  className = '',
}: IFreeContentCardProps) => {
  return (
    <div id={styles.CardContainer} className={[isHighlight ? styles.highlightCard : '', className].join(' ')} style={{ width: isMobile ? "10rem" : "" }}>
      <div
        className={`${styles.cardHeader} ${
          isMobile && styles.cardHeaderMobile
        }`}
      >
        <img src={img || "/assets/icon/computer.svg"} alt="" />
        <span>{title}</span>
      </div>
      <span
        className={`${styles.cardDescription} ${
          isMobile && styles.cardDescriptionMobile
        }`}
      >
        {description}
      </span>
    </div>
  );
};

export default FreeContentCard;
