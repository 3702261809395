import { useCallback, useState } from "react";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { EventsApi } from "services/api/events/events/EventsApi";
import GetEventsAllowedUsersUseCase from "modules/events/application/useCases/events/GetEventsAllowedUsersUseCase";
import { IEventsAllowedUsersResponse } from "modules/events/domain/dto/IEventsResponse";
import PostEventsAllowedUsersUseCase from "modules/events/application/useCases/events/PostEventsAllowedUsersUseCase";
import DeleteEventsAllowedUsersUseCase from "modules/events/application/useCases/events/DeleteEventsAllowedUsersUseCase";
import GetLocalAllowedUsersUseCase from "modules/events/application/useCases/events/GetLocalAllowedUsersUseCase";
import DeleteLocalAllowedUsersUseCase from "modules/events/application/useCases/events/DeleteLocalAllowedUsersUseCase";
import PostLocalAllowedUsersUseCase from "modules/events/application/useCases/events/PostLocalAllowedUsersUseCase";
import { ILocalUsersResponse } from "modules/events/domain/dto/ILocalUsersResponse";
import GetLocalUsersUseCase from "modules/events/application/useCases/events/GetLocalUsersUseCase";

interface IUseAddColaborador {
  getEventsAllowedUsers: (eventId: string) => void
  eventsAllowedUsers: IEventsAllowedUsersResponse[] | undefined
  postEventsAllowedUsers: (eventId: string, allowedUserIds: string[]) => void
  deleteEventsAllowedUsers: (eventId: string, allowedUserId: string) => void
  getLocalAllowedUsers: () => void
  localAllowedUsers: IEventsAllowedUsersResponse[] | undefined
  postLocalAllowedUsers: (allowedUserIds: string[]) => void
  deleteLocalAllowedUsers: (allowedUserId: string) => void
  getLocalUsers: (eventId?: string) => void
  localUsers: ILocalUsersResponse[] | undefined
}

export const UseAddColaborador = (): IUseAddColaborador => {
  const { currentLocal } = useLocal();
  const [eventsAllowedUsers, setEventsAllowedUsers] = useState<IEventsAllowedUsersResponse[] | undefined>()
  const [localAllowedUsers, setLocalAllowedUsers] = useState<IEventsAllowedUsersResponse[] | undefined>()
 const [localUsers, setLocalUsers] = useState<ILocalUsersResponse[] | undefined>()

  const getEventsAllowedUsers = useCallback(async (eventId: string) => {
    const eventService = EventsApi();
    if (currentLocal) {
      const response = await GetEventsAllowedUsersUseCase(eventService, eventId, currentLocal.id)
      setEventsAllowedUsers(response)
    }
  }, [currentLocal])

  const getLocalUsers  = useCallback(async (eventId?: string) => {
    const eventService = EventsApi();
    if (currentLocal) {
      const response = await GetLocalUsersUseCase(eventService, currentLocal.id, eventId)
      setLocalUsers(response)
    }
  }, [currentLocal])

  const deleteEventsAllowedUsers = useCallback(async (eventId: string, allowedUserId: string) => {
    const eventService = EventsApi();
    if (currentLocal) {
      await DeleteEventsAllowedUsersUseCase(eventService, eventId, allowedUserId, currentLocal.id)
      getEventsAllowedUsers(eventId)
      getLocalUsers(eventId)
    }
  }, [currentLocal])

  const postEventsAllowedUsers = useCallback(async (eventId: string, allowedUserIds: string[]) => {
    const eventService = EventsApi();
    if (currentLocal) {
      await PostEventsAllowedUsersUseCase(eventService, eventId, currentLocal.id, allowedUserIds)
    }
  }, [currentLocal])

  const getLocalAllowedUsers = useCallback(async () => {
    const eventService = EventsApi();
    if (currentLocal) {
      const response = await GetLocalAllowedUsersUseCase(eventService, currentLocal.id)
      setLocalAllowedUsers(response)
    }
  }, [currentLocal])

  const deleteLocalAllowedUsers = useCallback(async (allowedUserId: string) => {
    const eventService = EventsApi();
    if (currentLocal) {
      await DeleteLocalAllowedUsersUseCase(eventService, allowedUserId, currentLocal.id)
      getLocalAllowedUsers()
      getLocalUsers()
    }
  }, [currentLocal])

  const postLocalAllowedUsers = useCallback(async (allowedUserIds: string[]) => {
    const eventService = EventsApi();
    if (currentLocal) {
      await PostLocalAllowedUsersUseCase(eventService, currentLocal.id, allowedUserIds)
    }
  }, [currentLocal])
  
  return {
    getEventsAllowedUsers,
    eventsAllowedUsers,
    postEventsAllowedUsers,
    deleteEventsAllowedUsers,
    getLocalAllowedUsers,
    localAllowedUsers,
    postLocalAllowedUsers,
    deleteLocalAllowedUsers,
    getLocalUsers,
    localUsers
  };
};
