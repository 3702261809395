import { IGetConsultDocumentsParams } from "../../domain/dto/IGetConsultDocumentsParams";
import { IConsultDocumentsfilter } from "../../presentation/components/consultDocumentsFilter/interfaces/ConsultDocumentsFilter";


const ParseFilterToRequestUseCase = (pagination?: { page?: number, pageSize?: number }, filter?: IConsultDocumentsfilter, format?: string) => {

  const request: IGetConsultDocumentsParams = {
    startDate: filter?.startDate,
    endDate: filter?.endDate,
    keyword: filter?.keyword,
    page: pagination?.page,
    pageSize: pagination?.pageSize,
    format: format
  };

  return request;
};

export default ParseFilterToRequestUseCase

