import React, { FC, useCallback, useRef, useState } from 'react'
import styles from './ProductActions.module.scss'
import { ChangeStockControlModal } from './changeStockControlModal/ChangeStockControlModal'
import { ChangeStoreModal } from './changeStoreModal/ChangeStoreModal'
import { ChangePrinterModal } from './changePrinterModal/ChangePrinterModal'
import { DeleteProductsModal } from './deleteProductsModal/DeleteProductsModal'
import { Button, Icon, Menu, MenuItem } from '@material-ui/core'
import { IProductActionEnum } from '../../interfaces/IProductActionEnum'
import { useAuth } from 'modules/auth/presentation/context/AuthContext'
import UseProductActions from './UseProductActions'
import { ChangeTaxDataModal } from './changeTaxDataModal/ChangeTaxDataModal'
import { IProductItem } from '../categoryAndProducts/productList/IProductItem'
import { IProduct } from '../../fragments/catalogFragment/CatalogFragment'
import SidesheetSharedProducts from './sidesheetSharedProducts/SidesheetSharedProducts'
import SidesheetSharedChangeTax from './sidesheetSharedChangeTax/SidesheetSharedChangeTax'
import { AlertModal } from '../alertModal/AlertModal'

export interface IProductActionsProps {
    reloadList: () => Promise<void>,
    selectedProducts: IProductItem[],
}

const ProductActionsFragment: FC<IProductActionsProps> = ({ reloadList, selectedProducts }) => {
    const { hasAccessRole } = useAuth();
    const [showSharedModal, setShowSharedModal] = useState(false); // State to manage shared modal visibility
    const [showSharedChangeTaxModal, setShowSharedChangeTaxModal] = useState(false);
    // const [isShared, setIsShared] = useState(false); // State to manage shared modal visibility

    const {
        onClickAction,
        changePrinterModalOpen,
        changeStockControlModalOpen,
        changeStoreModalOpen,
        changeTaxDataModalOpen,
        deleteProductsModalOpen,
        onCloseModals,
        onChangePrinter,
        onChangeStore,
        onChangeStockControl,
        onChangeTaxData,
        onDeleteProducts,
        printers,
        stores,
        isShared,
        closeModalAlertShared,
        titleError,
        modalError,
        subTitleError
    } = UseProductActions(reloadList, selectedProducts);

    const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickActionHandle = useCallback(
        (action: IProductActionEnum) => {
            const hasSharedProducts = selectedProducts.some(product => product.shared);
            if (action === IProductActionEnum.DELETE_PRODUCTS) {
                setShowSharedModal(hasSharedProducts);
            }
            else if (action === IProductActionEnum.CHANGE_TAX_DATA) {
                setShowSharedChangeTaxModal(hasSharedProducts);               
            }
            onClickAction?.(action);
            handleClose();
        },
        [onClickAction, selectedProducts]
    );

    const onChangeDeleteProductsShared = (shared: boolean) => {         
        isShared.current = shared;
        onDeleteProducts();
    }

    const onChangeChangeTaxShared = (shared: boolean, taxData: { cfop: string;  cest: string; ncm: string; }) => {
        isShared.current = shared;
        onChangeTaxData(taxData, shared)
    }

    const sharedProductsShared = selectedProducts.filter(product => product.shared);


    return (
        !selectedProducts.length ? null : (
            <div id={styles.ProductActions}>
                <div className={styles.container}>
                    <div><b>{selectedProducts.length}</b> produto{selectedProducts.length > 1 && "s"} selecionado{selectedProducts.length > 1 && "s"}</div>
                    <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        variant="outlined"
                        className={styles.action}
                        onClick={handleClick}
                    >
                        Ações
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => onClickActionHandle(IProductActionEnum.CHANGE_PRINTER)}>
                            <Icon>printer</Icon>Alterar impressora
                        </MenuItem>
                        <MenuItem onClick={() => onClickActionHandle(IProductActionEnum.CHANGE_STOCK_CONTROL)}>
                            <Icon>inventory_2</Icon>Alterar controle de estoque
                        </MenuItem>
                        <MenuItem onClick={() => onClickActionHandle(IProductActionEnum.CHANGE_STORE)}>
                            <Icon>storefront</Icon>Alterar loja
                        </MenuItem>
                        <MenuItem onClick={() => onClickActionHandle(IProductActionEnum.CHANGE_TAX_DATA)}>
                            <Icon>account_balance</Icon>Alterar dados fiscais
                        </MenuItem>
                        <MenuItem onClick={() => onClickActionHandle(IProductActionEnum.DELETE_PRODUCTS)}>
                            <Icon>delete</Icon>Excluir produtos
                        </MenuItem>
                    </Menu>
                </div>

                <ChangePrinterModal
                    open={changePrinterModalOpen}
                    onClose={onCloseModals}
                    title="Alterar impressora"
                    printers={printers}
                    onConfirm={onChangePrinter}
                />
                <ChangeStockControlModal
                    open={changeStockControlModalOpen}
                    onClose={onCloseModals}
                    title="Alterar controle de estoque"
                    onConfirm={onChangeStockControl}
                />
                <ChangeStoreModal
                    open={changeStoreModalOpen}
                    onClose={onCloseModals}
                    title="Alterar loja"
                    stores={stores}
                    onConfirm={onChangeStore}
                />
                 {showSharedChangeTaxModal ? (
                    <SidesheetSharedChangeTax
                        openSidesheet={showSharedChangeTaxModal}
                        setOpenSidesheet={() => setShowSharedChangeTaxModal(false)}
                        products={sharedProductsShared}
                        onChangeChangeTaxShared={onChangeChangeTaxShared}

                    />
                ) : (
                    <ChangeTaxDataModal
                        open={changeTaxDataModalOpen}
                        onClose={onCloseModals}
                        title="Alterar dados fiscais"
                        onConfirm={onChangeTaxData}
                    />
                )}
                {showSharedModal ? (
                    <SidesheetSharedProducts
                        openSidesheet={showSharedModal}
                        setOpenSidesheet={() => setShowSharedModal(false)}
                        products={sharedProductsShared}
                        onChangeDeleteProductsShared={onChangeDeleteProductsShared}
                    />
                ) : (
                    <DeleteProductsModal
                        open={deleteProductsModalOpen}
                        onClose={onCloseModals}
                        title="Excluir produtos?"
                        onConfirm={onDeleteProducts}
                    />
                )}
                
                <AlertModal
                    open={modalError !== null}
                    title={<p>{titleError.current} <b>{subTitleError.current}</b></p>}                  
                    text={modalError || ""}               
                    onClose={closeModalAlertShared}
                    confirmLabel={'Excluir'}
                    cancelLabel={'Fechar'}
                />  
            </div>
        )
    );
};

export default ProductActionsFragment;
