import { ICatalogService } from "modules/catalog/domain/interface/ICatalogService"

const EnableProductUseCase = async (service: ICatalogService, localId: string, productId: string, value: boolean, shared: boolean) => {
   
        if (value) {
            await service.enableProduct(localId, productId, shared);
        } else {
            await service.disableProduct(localId, productId, shared);
        }
   
}

export default EnableProductUseCase
