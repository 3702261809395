import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './CatalogFragment.module.scss'
import CategoryList from '../../componentes/categoryAndProducts/categoryList/CategoryList'
import { ICategoryItem } from '../../componentes/categoryAndProducts/categoryList/ICategoryItem'
import { IProductItem } from '../../componentes/categoryAndProducts/productList/IProductItem'
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import CatalogService from 'services/api/catalog/CatalogService'
import GetCatalogProductCategoryListUseCase from 'modules/catalog/application/useCases/GetCatalogProductCategoryListUseCase'
import GetProductListByListUseCase from 'modules/catalog/application/useCases/GetProductListByCategoryAndCatalogUseCase'
import { useHistory, useParams } from 'react-router-dom'
import { Drawer, Icon, IconButton } from '@material-ui/core'
import CatalogCategoryFragment from '../catalogCategoryFragment/CatalogCategoryFragment'
import EnableProductUseCase from 'modules/catalog/application/useCases/EnableProductUseCase'
import PopMenu from '../../componentes/ui/PopMenu'
import ProductFilter from '../../componentes/productFilters/ProductFilter'
import { IProductFilter } from '../../interfaces/IProductFilter'
import GetCatalogProductCategoriesUseCase from 'modules/catalog/application/useCases/GetCatalogProductCategoriesUseCase'
import ProductActionsFragment from '../../componentes/productActions/ProductActions'
import { AlertModal } from '../../componentes/alertModal/AlertModal'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import DeleteCatalogUseCase from 'modules/catalog/application/useCases/_DeleteCatalogUseCase'
import CopyCatalogUseCase from 'modules/catalog/application/useCases/CopyCatalogUseCase'
import DeleteProductUseCase from 'modules/catalog/application/useCases/DeleteProductUseCase'
import ImportProductSheet, { IDetectedProductValue } from '../../componentes/importProductSheet/ImportProductSheet'
import AlertDeleteCategory from '../../componentes/categoryAndProducts/alertDeleteCategory/AlertDelteCategory'
import DeleteCatalogProductCategoryUseCase from 'modules/catalog/application/useCases/DeleteCatalogProductCategoryUseCase'
import ImportProductUseCase from 'modules/catalog/application/useCases/ImportProductUseCase'
import CopyProductUseCase from 'modules/catalog/application/useCases/CopyProductUseCase'
import CatalogSkeleton from '../../componentes/complement/catalogSkeleton/CatalogSkeleton'
import qs from 'qs'
import CreateProductDrawer from '../../componentes/CreateProductDrawer/CreateProductDrawer'
import RemoveProductUseCase from 'modules/catalog/application/useCases/RemoveProductUseCase'
import { AddCircle, DeleteOutlined, EditOutlined, EventOutlined } from '@material-ui/icons'
import { ContentCopyOutlined, PercentOutlined } from '@mui/icons-material'
import Button from 'components/ui/Button/Button'
import AccessProductForm from '../../componentes/productActions/accessProductForm/AccessProductForm'
import UseProductAccess from '../../hooks/UseProductAccess'
import { useAuth } from 'modules/auth/presentation/context/AuthContext'
import HideCatalogProductUseCase from 'modules/catalog/application/useCases/HideCatalogProductUseCase'
import { ICatalogListItem } from '../../componentes/catalogListTab/CatalogListTab'
import GetConfigProductsUseCase from 'modules/catalog/application/useCases/GetConfigProductList'
import CategoryItem from '../../componentes/categoryAndProducts/categoryList/CategoryItem'
import { v4 } from 'uuid'
import ExportProductSheet from '../../componentes/exportProductSheet/ExportProductSheet'
import PutFavoriteUseCase from 'modules/catalog/application/useCases/PutFavoriteUseCase'
import UseDimension from 'components/dimension/UseDimension'

export interface IProduct extends IProductItem { }
export interface ICategory extends ICategoryItem { }
export interface IProductCatalogProps {
    catalogs: ICatalogListItem[],
    getCatalogs: () => void,
}
const PAGESIZE = 200;

const catalogService = CatalogService();

const CatalogFragment: FC<IProductCatalogProps> = ({ catalogs, getCatalogs }) => {
    const [categories, setCategories] = useState<ICategory[]>();
    const [categoriesDropDownList, setCategoriesDropDownList] = useState<ICategory[]>();
    const { currentLocal } = useLocal();
    const { push } = useHistory();
    const { catalogId } = useParams<{ catalogId: string, categoryId: string }>();
    const [selectedProducts, setSelectedPRoducts] = useState<IProduct[]>([]);
    const [openAddProductDrawer, setOpenAddProductDrawer] = useState(false)
    const selectedProductsRef = useRef<IProduct[]>(selectedProducts);
    const [isLoadingCategory, setIsLoadingCategory] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState<ICategory>();
    const [disabledHideProduct, setDisabledHidProduct] = useState(false);

    const { hasAccessRole } = useAuth();
    const { dimensions } = UseDimension();

    const [filter, setFilter] = useState<IProductFilter>({
        keyword: "",
        page: 0, //inicia com zero
        pageSize: PAGESIZE,
    });

    const [hideEmptyCategory, setHideEmptyCategory] = useState(true)
    const [removeCatalogModal, setRemoveCatalogModal] = useState(false);
    const [copyCatalogModal, setCopyCatalogModal] = useState(false);
    const [modalError, setModalError] = useState<string | null>(null);
    const titleError = useRef<string>();
    const subTitleError = useRef<string>();
    const expectedMessage = "Este é um produto compartilhado com outros estabelecimentos. Para realizar esta ação você precisa de permissão em todos eles.";


    const { showLoading, hideLoading } = useUi();
    const { getAccessUsers, onSubmitAccessProductHandle, getDefaultAccessUsers, setProductToEditAccess, productToEditAccess } = UseProductAccess();

    const handleError = useCallback(
        (message: string) => {
          setModalError(message);
        },
        [setModalError]
      );

    const closeModalAlertShared = useCallback(() => {
        setModalError(null);
        titleError.current = undefined
        subTitleError.current = undefined

    },[])


    const onClickAddProductHandle = useCallback((category?: ICategory) => {
        setOpenAddProductDrawer(false);
        const query = {
            categoryId: category?.id,
            catalogId: catalogId ?? ""
        }
        const stringQuery = qs.stringify(query)
        push(`/private/catalog/product/add?${stringQuery}`);
    }, [catalogId, push]);

    const onClickEditProductHandle = useCallback((product: IProduct, catalogId?: string) => {
        const storedCategories = sessionStorage.getItem('@categories');
        if (storedCategories) {
            push(`/private/catalog/product/edit?productId=${product.id}&catalogId=${catalogId}&filterCategories=true`)
        } else {
            push(`/private/catalog/product/edit?productId=${product.id}&catalogId=${catalogId}`);
        }

    }, [push]);

    const onClickEditCatalogHandle = useCallback(() => {
        push(`/private/catalog/${catalogId}?action=editCatalog`);
    }, [catalogId, push]);

    const onAddClickExistsProductHandle = useCallback(() => {
        setOpenAddProductDrawer(false);
        push(`/private/catalog/${catalogId}?action=editCatalog&step=products&direct=true`);
    }, [catalogId, push]);

    const onAddClickScheduleEvent = useCallback(() => {
        push(`/private/catalog/product/schedule`);
    }, [push]);

    const onClickApplyDiscountProductHandle = useCallback(() => {
        push(`/private/catalog/${catalogId}?action=editCatalog&step=discount&direct=true`);
    }, [catalogId, push]);

    const onClickCopyCatalogHandle = useCallback(() => {
        setCopyCatalogModal(true)
    }, []);

    const onClickDeleteCatalogHandle = useCallback(() => {
        setRemoveCatalogModal(true);
    }, []);

    const onClickRemoveProductHandle = useCallback(async (product: IProduct) => {
        await RemoveProductUseCase(catalogService, catalogId, product.id);
    }, [catalogId]);

    const onClickDeleteProductHandle = useCallback(async (product: IProduct, shared: boolean) => {
        try {
            await DeleteProductUseCase(catalogService, product.id, shared);
        }  catch (error: any) {
            if (error.response.data.message === expectedMessage) {
                titleError.current = 'Excluír'
                subTitleError.current = 'produtos'
                handleError(expectedMessage);
            }
        } finally {
            
        }
    }, [handleError]);

    const getCategories = useCallback(async () => {
        if (currentLocal) {
            try {
                setIsLoadingCategory(true)
                const categorieResponse = await GetCatalogProductCategoriesUseCase(catalogService, currentLocal.id, !hideEmptyCategory ? undefined : catalogId, (catalogId ? true : hideEmptyCategory) || !!filter.keyword?.length, filter.keyword);
                const visibleCategories = categorieResponse?.filter(x => !!filter.categories?.length ? filter.categories?.find(y => y.id === x.id) : true)
                setCategories(visibleCategories);
                setSelectedPRoducts([]);
            } finally {
                setIsLoadingCategory(false)
            }
        }
    }, [catalogId, currentLocal, filter.categories, filter.keyword, hideEmptyCategory])


    const onClickEnabledProductHandle = useCallback(async (product: IProduct, value: boolean, shared: boolean): Promise<boolean | void> => {
        try {
            if (currentLocal) {
                await EnableProductUseCase(catalogService, currentLocal.id, product.id, value, shared);
                return value;
            }
            return !value;
        } catch (error: any) {
            if (error.response.data.message === expectedMessage) {
                titleError.current = 'Habilitar/Desabilitar'
                subTitleError.current = 'produtos'
                handleError(expectedMessage);
            }
            return !value;
        } finally {            
            getCategories()
        }
    }, [currentLocal, getCategories, handleError]);
    

    const onClickFeaturedProductHandle = useCallback(async (product: IProduct, value: boolean, shared?: boolean): Promise<void> => {
        try {
            const body = {
                localId:  product.localId,
                enabled: value,
                AllSharedProducts: shared
            }
            await PutFavoriteUseCase(catalogService, product.id, body);
        }  catch (error: any) {  
            if (error.response.data.message === expectedMessage) {
                titleError.current = 'Favoritar'
                subTitleError.current = 'produtos'
                handleError(expectedMessage);
                getCategories()
            }
        }
    }, [getCategories, handleError]);

    const getCategoriesDropDownList = useCallback(async () => {
        if (currentLocal) {

            const categorieResponse = await GetCatalogProductCategoryListUseCase(catalogService, currentLocal.id);
            setCategoriesDropDownList(categorieResponse);

        }
    }, [currentLocal])

    const onConfirmDeleteCatalogHandler = useCallback(async () => {

        try {
            if (currentLocal?.id) {
                showLoading();
                await DeleteCatalogUseCase(catalogService, currentLocal.id, catalogId);
                setRemoveCatalogModal(false);
                getCatalogs();
            }
        } finally {
            hideLoading();
        }
    }, [catalogId, currentLocal?.id, getCatalogs, hideLoading, showLoading]);

    const onConfirmCopyCatalogHandler = useCallback(async () => {
        try {
            if (currentLocal?.id) {
                showLoading();
                await CopyCatalogUseCase(catalogService, currentLocal.id, catalogId);
                setCopyCatalogModal(false);
                getCategories();
                getCatalogs();
            }
        } finally {
            hideLoading();
        }
    }, [catalogId, currentLocal?.id, getCategories, getCatalogs, hideLoading, showLoading]);

    useEffect(() => {
        getCategories();
    }, [getCategories])

    useEffect(() => {
        getCategoriesDropDownList()
    }, [getCategoriesDropDownList])

    const getProductsHandle = useCallback(async (category: ICategory) => {
        if (currentLocal && filter) {
            const response = await GetProductListByListUseCase(catalogService, currentLocal.id, catalogId, category.id, filter);
            return (response);
        } else {
            throw (new Error("Falha ao obter produtos da categoria"));
        }
    }, [catalogId, currentLocal, filter]);

    const onClickAddCategoryHandle = useCallback(() => {
        push(`/private/catalog/${catalogId ?? ""}?action=addCategory`)
    }, [catalogId, push]);

    const onClickEditCategoryHandle = useCallback((category: ICategory) => {
        push(`/private/catalog/${catalogId ?? ""}?action=editCategory&categoryId=${category.id}`)
    }, [catalogId, push]);

    const onClickCloseCategoryHandle = useCallback(() => {
        push(`/private/catalog/${catalogId ?? ""}`)
    }, [catalogId, push]);

    const onChangeFilterHandle = useCallback((_filter: IProductFilter) => {
        setFilter(_filter);
    }, []);

    const onClickRemoveCategoryHandle = useCallback(async (category: ICategory) => {
        setCategoryToDelete(category)
    }, [])

    const onCloseRemoveCategoryHandle = useCallback(async () => {
        setCategoryToDelete(undefined)
    }, [])
    const onConfirmRemoveCategoryHandle = useCallback(async (category: ICategory, categoryToReceive?: ICategory | null) => {
        try {
            if (currentLocal?.id) {
                showLoading();
                await DeleteCatalogProductCategoryUseCase(catalogService, currentLocal.id, category.id, categoryToReceive?.id);
                setCategoryToDelete(undefined);
                getCategories();
            }
        } finally {
            hideLoading();
        }
    }, [currentLocal?.id, getCategories, hideLoading, showLoading]);

    const onSelectProductHandle = useCallback((product: IProduct, checked: boolean) => {
        if (checked) {
            setSelectedPRoducts(prev => [...prev, product]);
        } else {
            setSelectedPRoducts(prev => prev.filter(item => item.id !== product.id));
        }
    }, []);

    useEffect(() => {
        selectedProductsRef.current = selectedProducts;
        if (selectedProducts.length > 0) {
            setDisabledHidProduct(true)
        } else {
            setDisabledHidProduct(false)
        }
    }, [selectedProducts])

    const onClickCopyProductHandle = useCallback(async (product: IProduct, shared?: boolean) => {
        try {
            if (currentLocal?.id) {
                showLoading();
                await CopyProductUseCase(catalogService, currentLocal?.id, product.id, shared);
                getCategories();
            }

         } catch (error: any) {  
            if (error.response.data.message === expectedMessage) {
                titleError.current = 'Duplicar'
                subTitleError.current = 'produtos'
                handleError(expectedMessage);
            }
        } finally {
            hideLoading();
            

        }

    }, [currentLocal?.id, getCategories, handleError, hideLoading, showLoading])

    const onClickAccessProductHandle = useCallback(async (product: IProduct, shared?: boolean) => {
        const body = {...product, AllSharedProducts: shared}
        setProductToEditAccess(body)
    }, [setProductToEditAccess]);

    const onClickHideProductHandle = useCallback(async (product: IProduct, value: boolean, shared?: boolean) => {
        if (currentLocal) {
            try {
                await HideCatalogProductUseCase(catalogService, catalogId, product.id, value, shared);
            } catch (error: any) {  
                if (error.response.data.message === expectedMessage) {
                    titleError.current = 'Visibilidade do produto'
                    handleError(expectedMessage);
                }
            } finally {
                ;
            }

        }
    }, [catalogId, currentLocal, handleError]);

    const getConfigProducts = useCallback(async () => {
        if (currentLocal) {
            const response = await GetConfigProductsUseCase(catalogService, currentLocal.id);
            return response;
        } else {
            throw (new Error("Falha ao obter produtos da categoria, local não selecionado"));
        }
    }, [currentLocal]);

    const configProductCategoryList = useMemo(() => <CategoryItem
        categoryItem={{
            id: v4(),
            description: "Configuração",
            name: "Configuração"
        }}
        getProducts={getConfigProducts}
        onClickEditProduct={onClickEditProductHandle}
        onChangeEnabledProduct={onClickEnabledProductHandle}
        onClickProductAccess={hasAccessRole('LimitarAcessoProduto') ? onClickAccessProductHandle : undefined}
        currentCatalogId={catalogId}
    />, [catalogId, getConfigProducts, hasAccessRole, onClickAccessProductHandle, onClickEditProductHandle, onClickEnabledProductHandle])
    useEffect(() => {
        getConfigProducts();
    }, [getConfigProducts])

    const categoryList = useMemo(() =>
        isLoadingCategory || !categories
            ? <CatalogSkeleton />
            : !!categories.length
                ? <CategoryList
                    categoryList={[...categories] ?? []}
                    getProducts={getProductsHandle}
                    onClickEditCategory={onClickEditCategoryHandle}
                    onClickRemoveCategory={onClickRemoveCategoryHandle}
                    onClickEditProduct={onClickEditProductHandle}
                    onClickCopyProduct={onClickCopyProductHandle}
                    onClickAddProduct={onClickAddProductHandle}
                    onClickRemoveProduct={catalogId ? onClickRemoveProductHandle : undefined}
                    onClickDeleteProduct={onClickDeleteProductHandle}
                    onChangeFeaturedProduct={onClickFeaturedProductHandle}
                    onChangeEnabledProduct={onClickEnabledProductHandle}
                    onClickCheckProducts={onSelectProductHandle}
                    onClickProductAccess={hasAccessRole('LimitarAcessoProduto') ? onClickAccessProductHandle : undefined}
                    disabledHidProduct={disabledHideProduct}
                    currentCatalogId={catalogId}
                    onChangeHide={onClickHideProductHandle}
                />
                : <div className={styles.fallback}>
                    <img src="/assets/img/empty-box.png" alt="" />
                    {!!filter.keyword || (filter.categories && filter.categories?.length > 0)
                        ? <span>Ops, parece que você <b>ainda não tem produtos</b> para serem mostrados aqui!</span>
                        : <>
                            <span>Para começar crie sua <b>primeira categoria</b> </span>
                            <Button
                                variant='outlined'
                                color='secondary'
                                // fullWidth
                                className={styles.button}
                                onClick={onClickAddCategoryHandle}
                            >
                                <Icon>add</Icon> Nova Categoria
                            </Button>
                        </>
                    }
                </div>
        , [isLoadingCategory, categories, getProductsHandle, onClickEditCategoryHandle, onClickRemoveCategoryHandle, onClickEditProductHandle, onClickCopyProductHandle, onClickAddProductHandle, catalogId, onClickRemoveProductHandle, onClickDeleteProductHandle, onClickFeaturedProductHandle, onClickEnabledProductHandle, onSelectProductHandle, hasAccessRole, onClickAccessProductHandle, disabledHideProduct, onClickHideProductHandle, filter.keyword, filter.categories, onClickAddCategoryHandle])

    const catalogAction = useMemo(() => [
        { label: `Editar`, icon: <EditOutlined />, action: onClickEditCatalogHandle },
        { label: `Aplicar desconto`, icon: <PercentOutlined />, action: onClickApplyDiscountProductHandle },
        { label: `Duplicar`, icon: <ContentCopyOutlined />, action: onClickCopyCatalogHandle },
        { label: `Excluir`, icon: <DeleteOutlined />, action: onClickDeleteCatalogHandle },
        { label: `Agendamento`, icon: <EventOutlined />, action: onAddClickScheduleEvent }
    ], [onClickEditCatalogHandle, onClickApplyDiscountProductHandle, onClickCopyCatalogHandle, onClickDeleteCatalogHandle, onAddClickScheduleEvent])

    const onSubmitImportProductSheetHandle = useCallback(async (products: IDetectedProductValue[]) => {
        try {
            if (currentLocal?.id) {
                showLoading();
                await ImportProductUseCase(catalogService, currentLocal.id, [catalogId], products);
                getCategories();
            }
        } finally {
            hideLoading()
        }
    }, [catalogId, currentLocal?.id, getCategories, hideLoading, showLoading])

    const showEmptyCategory = useMemo(() => <div>        {hideEmptyCategory ?
        <div onClick={() => setHideEmptyCategory(false)}>
            Exibir categorias vazias
            <IconButton onClick={() => setHideEmptyCategory(false)}>
                <Icon>visibility_off</Icon>
            </IconButton>
        </div>
        :
        <div onClick={() => setHideEmptyCategory(true)}>
            Ocultar categorias vazias
            <IconButton onClick={() => setHideEmptyCategory(true)}>
                <Icon>visibility</Icon>
            </IconButton>
        </div>
    }
    </div>, [hideEmptyCategory])

    return (
        <>
            <div id={styles.ProductCatalog} >
                <div className={styles.container} >
                    <div className={styles.header} >
                        <div className={styles.tip}>
                            <span>✨ <b>Dica da Meep:</b> imagens de boa qualidade nos produtos aumentam as vendas!</span>
                        </div>

                        <div className={styles.actionsContainer}>
                            <div className={styles.divButtons}>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    className={styles.button}
                                    onClick={onClickAddCategoryHandle}
                                >
                                    Nova Categoria
                                </Button>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    className={styles.button}
                                    onClick={() => setOpenAddProductDrawer(true)}
                                    endIcon={<AddCircle />}
                                >
                                    Novo Produto
                                </Button>
                            </div>
                            {!catalogId && showEmptyCategory}

                            {catalogId && <div>
                                {dimensions.width > 400 && "Ações do cardápio"}
                                <PopMenu
                                    actions={catalogAction}
                                />
                            </div>
                            }
                            {catalogId && <ImportProductSheet
                                onSubmit={onSubmitImportProductSheetHandle}
                            />}

                            {!catalogId && hasAccessRole("ExportarProdutoPortal") && <ExportProductSheet />}
                        </div>
                    </div>
                    <ProductFilter
                        setFilterExport={() => { }}
                        onChangeFilter={onChangeFilterHandle}
                        filter={filter}
                        categoriesList={categoriesDropDownList}
                    />
                    <ProductActionsFragment
                        selectedProducts={selectedProducts}
                        reloadList={getCategories}
                    />


                    {categoryList}
                    {!catalogId && configProductCategoryList}
                </div>
                <CatalogCategoryFragment reloadCategories={getCategories} onClose={onClickCloseCategoryHandle} />
                <Drawer open={openAddProductDrawer} onClose={() => setOpenAddProductDrawer(false)} anchor='right'>
                    <CreateProductDrawer
                        onClose={() => setOpenAddProductDrawer(false)}
                        onClickNew={onClickAddProductHandle}
                        onClickExist={onAddClickExistsProductHandle} 
                        catalogId={catalogId}
                        />
                        
                </Drawer>
                {catalogs.length > 1 ?
                    <AlertModal
                        open={removeCatalogModal}
                        title={<h2>Excluir <b>Cardápio</b></h2>}
                        text={"Deseja realmente excluir seu cardápio?"}
                        subText={<span><b>Atenção:</b> ao confirmar a exclusão, o cardápio não poderá ser recuperado.</span>}
                        onClose={() => setRemoveCatalogModal(false)}
                        onConfirm={onConfirmDeleteCatalogHandler}
                        confirmLabel='Excluir'
                        cancelLabel='Voltar'
                    />
                    :
                    <AlertModal
                        open={removeCatalogModal}
                        title={<h2>Excluir <b>Cardápio</b></h2>}
                        text={"Não é possivel excluir todos os cardapios."}
                        subText={<span><b>Atenção:</b>você precisa ter ao menos um cardápio cadastrado.</span>}
                        onClose={() => setRemoveCatalogModal(false)}
                        confirmLabel='OK'
                        cancelLabel='Voltar'
                    />
                }
                <AlertModal
                    open={copyCatalogModal}
                    title={<h2>Duplicar <b>Cardápio</b></h2>}
                    text={"Deseja duplicar o cardápio?"}
                    // subText={"Atenção o após apagado o catalogo não poderá ser recuperado"}
                    onClose={() => setCopyCatalogModal(false)}
                    onConfirm={onConfirmCopyCatalogHandler}
                    confirmLabel='Duplicar'
                    cancelLabel='Voltar'
                />
                <AlertDeleteCategory
                    categories={categories ?? []}
                    categoryToDelete={categoryToDelete}
                    onConfirm={onConfirmRemoveCategoryHandle}
                    onClose={onCloseRemoveCategoryHandle}
                />

                <AlertModal
                    open={modalError !== null}
                    title={<p>{titleError.current} <b>{subTitleError.current}</b></p>}  
                    text={modalError || ""}               
                    onClose={closeModalAlertShared}
                    confirmLabel={'Excluir'}
                    cancelLabel={'Fechar'}
                />      

                <AccessProductForm
                    productToEditAccess={productToEditAccess}
                    onClose={() => setProductToEditAccess(undefined)}
                    onSubmit={onSubmitAccessProductHandle}
                    getAccessUser={getAccessUsers}
                    getDefault={getDefaultAccessUsers}
                />
            </div>
        </>
    )
}
export default CatalogFragment