import { ISaveCatalogProductRequest } from "modules/catalog/domain/dtos/IAddCatalogProductRequest"
import { ICatalogService } from "modules/catalog/domain/interface/ICatalogService"
import { IProductFormValue } from "modules/catalog/presentation/componentes/productForm/IProducFormValuet"

const AddProductUseCase = async (service: ICatalogService, localId: string, values: IProductFormValue) => {

    const request: ISaveCatalogProductRequest = {
        ...values,
        localId: localId,
        catalogIds: values.catalogs?.map(catalog => catalog.id) ?? [],
        categoryId: values.category?.id,
        defaultPrinterId: values.defaultPrinter?.id,
        storeId: values.store?.id,  
        eventId: values.event?.id,
        enabled: values.enabled ?? true,
    }

    request.sharedLocals = values.sharedLocals && values.sharedLocals.map(local => ({
        ...local,
        localId: local.id,
        categoryId: local.category?.id,
        catalogIds: local.catalogs?.map(catalog => catalog.id) ?? [],
        defaultPrinterId: local.defaultPrinter?.id,
        productId: local.productId
    }));

    await service.addProduct(localId, request);
}
export default AddProductUseCase